import React, { useCallback, useEffect, useState } from 'react';
// import "https://buzzinguniverse.iqspark.org/css_file/shop.css"
import { Helmet } from 'react-helmet';

function Shop() {
    const [stores, setStores] = useState([]);
    const [data, setDate] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [loading, setLoading] = useState(false)
    const [searchLocation, setSearchLocation] = useState('');

    const debouncedSearch = useCallback(
        (() => {
            let timeoutId;
            return (searchValue) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setStores([]);
                    setPageNumber(1);
                    fetchstores(1, searchValue);
                }, 3000);
            };
        })(),
        []
    );

    const handleLocationChange = (e) => {
        const value = e.target.value;
        setSearchLocation(value);
        debouncedSearch(value);
    };

    const fetchstores = async (page, search='') => {
        setPageNumber(page)
        setLoading(true)
        try {
            const response = await fetch(`https://api.buzzinguniverse.iqspark.org/public/api/stores?page=${page}${search ? `&search=${search}` : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data?.status == 200 || data?.success) {
                setStores(prevStores => [...prevStores, ...data?.data?.data])
                setDate(data?.data)
            }
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchstores(1)
    }, []);
    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://buzzinguniverse.iqspark.org/css_file/shop.css"
                />
            </Helmet>
            <div className='bp-nouveau archive post-type-archive post-type-archive-product logged-in wp-custom-logo theme-beehive woocommerce-shop woocommerce woocommerce-page woocommerce-js hfeed beehive beehive-user buddychat-is-active beehive-child elementor-default elementor-kit-32 title-bar-active beehive-social-layout panel-expanded has-page-sidebar js js-hiraku-offcanvas-body'>
                <div className="js-hiraku-offcanvas" aria-hidden="true" aria-labelledby="hiraku-offcanvas-btn-0" id="js-hiraku-offcanvas-0" aria-label="close">
                    <div id="beehive-social-panel" className="beehive-social-panel js-hiraku-offcanvas-sidebar js-hiraku-offcanvas-sidebar-left" role="navigation">
                        <div className="inner-panel ass-scrollbar mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar">
                            <div id="mCSB_1" className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" tabindex="0">
                                <div id="mCSB_1_container" className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" dir="ltr">
                                    <div className="panel-block dark">
                                        <a href="/" className="panel-logo item">
                                            <img src="https://buzzinguniverse.com/wp-content/uploads/2023/06/809890.png" alt="BuzzingUniverse- Login or Signup" className="mCS_img_loaded" />
                                        </a>
                                        <div className="my-card item">
                                            <div className="info">
                                                <a href="/members/shazzy/" className="profile-avatar">
                                                    <img src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-96.png" alt="Shazzy" className="avatar mCS_img_loaded" />
                                                </a>
                                                <div className="profile-name">
                                                    <a href="/members/shazzy/" className="name ellipsis">Shazzy</a>
                                                    <small>Member</small>
                                                </div>
                                            </div>
                                            <ul className="connections">
                                                <li>
                                                    <span className="count">0</span>
                                                    <p className="mute">Friends</p>
                                                </li>
                                                <li>
                                                    <span className="count">0</span>
                                                    <p className="mute">Groups</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="panel-block light">
                                        <div className="panel-menu item">
                                            <ul id="menu-dashboard-menu" className="navbar-panel">
                                                <li id="menu-item-74" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-74">
                                                    <a href="/activity/">
                                                        <i className=" uil-notebooks"></i>
                                                        <span className="nav-link-text">Activity</span>
                                                    </a>
                                                </li>
                                                <li id="menu-item-80" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-80">
                                                    <a href="/photos/">
                                                        <i className="uil-image-v"></i>
                                                        <span className="nav-link-text">Photos</span>
                                                    </a>
                                                </li>
                                                <li id="menu-item-83" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-83">
                                                    <a href="/members/">
                                                        <i className="uil-user"></i>
                                                        <span className="nav-link-text">People</span>
                                                    </a>
                                                </li>
                                                <li id="menu-item-78" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-78">
                                                    <a href="/groups/">
                                                        <i className="uil-users-alt"></i>
                                                        <span className="nav-link-text">Groups</span>
                                                    </a>
                                                </li>
                                                <li id="menu-item-75" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-75">
                                                    <a href="/adverts/">
                                                        <i className="uil-tv-retro"></i>
                                                        <span className="nav-link-text">Adverts</span>
                                                    </a>
                                                </li>
                                                <li id="menu-item-2014" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-2014">
                                                    <a href="/forums/">
                                                        <i className="uil-comments"></i>
                                                        <span className="nav-link-text">Forums</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" >
                                <div className="mCSB_draggerContainer">
                                    <div id="mCSB_1_dragger_vertical" className="mCSB_dragger">
                                        <div className="mCSB_dragger_bar" ></div>
                                        <div className="mCSB_draggerRail"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="beehive-page" className="site">
                    <header id="sochead" className="site-header social-header user-nav-active">
                        <nav className="navbar beehive-navbar social fixed-top to-top">
                            <div className="container">
                                <button className="beehive-toggler account-toggler js-hiraku-offcanvas-btn" type="button" data-toggle-offcanvas="#js-hiraku-offcanvas-1" aria-expanded="false" aria-label="Menu" aria-controls="js-hiraku-offcanvas-1" id="hiraku-offcanvas-btn-1">
                                    <img alt="" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-24.png" srcset="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-48.png  2x" className="avatar avatar-24 photo" height="24" width="24" decoding="async" />
                                </button>
                                <button className="beehive-toggler panel-toggler js-hiraku-offcanvas-btn" type="button" data-toggle-offcanvas="#js-hiraku-offcanvas-0" aria-expanded="false" aria-label="Menu" aria-controls="js-hiraku-offcanvas-0" id="hiraku-offcanvas-btn-0">
                                    <span className="icon-bar bar1"></span>
                                    <span className="icon-bar bar2"></span>
                                    <span className="icon-bar bar3"></span>
                                </button>

                                <ul id="navbar-user" className="navbar-nav navbar-user">
                                    <li id="friend-requests-list" className="nav-item dropdown friend-requests-list">
                                        <a className="nav-link dropdown-toggle" href="#" id="nav_friend_requests" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="uil-user-plus"></i>
                                            <span className="nav-item-title">Friend Requests</span>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="nav_friend_requests">
                                            <div className="dropdown-title">Friend requests</div>
                                            <div className="alert-message">
                                                <div className="alert alert-warning" role="alert">No friend request.</div>
                                            </div>
                                            <div className="dropdown-footer">
                                                <a href="/members/shazzy/friends/requests/" className="button">All Requests</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li id="notification-list" className="nav-item dropdown notification-list">
                                        <a className="nav-link dropdown-toggle" href="#" id="nav_notification" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="uil-bell"></i>
                                            <span className="nav-item-title">Notifications</span>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="nav_notification">
                                            <div className="dropdown-title">Notifications</div>
                                            <div className="alert-message">
                                                <div className="alert alert-warning" role="alert">No notifications found</div>
                                            </div>
                                            <div className="dropdown-footer">
                                                <a href="/members/shazzy/notifications/unread/" className="button">All Notifications</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li id="private-message-list" className="nav-item dropdown private-message-list">
                                        <a className="nav-link dropdown-toggle" href="#" id="nav_private_messages" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="uil-envelope-open"></i>
                                            <span className="nav-item-title">Messages</span>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="nav_private_messages">
                                            <div className="dropdown-title">Unread messages</div>
                                            <div className="alert-message">
                                                <div className="alert alert-warning" role="alert">No messages to read.</div>
                                            </div>
                                            <div className="dropdown-footer">
                                                <a href="/members/shazzy/messages/inbox/" className="button">All Messages</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="mini-cart nav-item">
                                        <a href="/cart/" className="cart-contents nav-link" title="View Cart">
                                            <i className="uil-cart"></i>
                                        </a>
                                    </li>
                                    <li id="myaccount-url-list" className="nav-item dropdown myaccount-url-list">
                                        <a className="nav-link dropdown-toggle" href="#" id="nav_my_account" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-200.png" className="avatar user-1398-avatar avatar-200 photo" width="200" height="200" alt="Profile picture of Shazzy" />
                                            <span className="account-name">@ shazzy</span>
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="nav_my_account">
                                            <ul id="menu-account-menu" className="member-account-menu">
                                                <li id="menu-item-4289" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-4289">
                                                    <a href="/activity/">Home</a>
                                                </li>
                                                <li id="menu-item-84" className="bp-menu bp-activity-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-84">
                                                    <a href="/members/shazzy/activity/">Activity</a>
                                                </li>
                                                <li id="menu-item-85" className="bp-menu bp-profile-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-85">
                                                    <a href="/members/shazzy/profile/">Profile</a>
                                                </li>
                                                <li id="menu-item-86" className="bp-menu bp-friends-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-86">
                                                    <a href="/members/shazzy/friends/">Friends</a>
                                                </li>
                                                <li id="menu-item-87" className="bp-menu bp-groups-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-87">
                                                    <a href="/members/shazzy/groups/">Groups</a>
                                                </li>
                                                <li id="menu-item-88" className="bp-menu bp-my-adverts-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-88">
                                                    <a href="/members/shazzy/my-adverts/">Adverts</a>
                                                </li>
                                                <li id="menu-item-5660" className="bp-forums-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-5660">
                                                    <a href="/members/Shazzy/forums/">Forums</a>
                                                </li>
                                                <li id="menu-item-91" className="bp-menu bp-notifications-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-91">
                                                    <a href="/members/shazzy/notifications/">Notifications</a>
                                                </li>
                                                <li id="menu-item-92" className="bp-menu bp-messages-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-92">
                                                    <a href="/members/shazzy/messages/">Messages</a>
                                                </li>
                                                <li id="menu-item-93" className="bp-menu bp-settings-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-93">
                                                    <a href="/members/shazzy/settings/">Settings</a>
                                                </li>
                                                <li id="menu-item-94" className="bp-menu bp-logout-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-94">
                                                    <a href="/wp-login.php?action=logout&amp;redirect_to=https%3A%2F%2Fbuzzinguniverse.com%2Fshop%2F&amp;_wpnonce=a802432ae5">Log Out</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>

                                <div id="beehive-ajax-search" className="beehive-ajax-search">
                                    <form role="search" method="get" id="ajax-search-form" className="ajax-search-form form-inline" action="https://buzzinguniverse.com/">
                                        <div className="search-field">
                                            <i className="icon ion-android-search"></i>
                                            <input id="ajax-search-textfield" type="text" name="s" placeholder="search..." value="" autocomplete="off" required="" />
                                            <span className="beehive-loading-ring"></span>
                                        </div>
                                        <div className="search-button">
                                            <button type="submit" className="search-submit">
                                                <i className="icon ion-android-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                    <div id="ajax-search-result"></div>
                                </div>
                                <div main-logo-header="">
                                    <a className="navbar-brand" href="/">
                                        <img src="https://buzzinguniverse.com/wp-content/uploads/2023/06/809890-1.png" title="BuzzingUniverse- Login or Signup" alt="BuzzingUniverse- Login or Signup" className="default-logo" />
                                    </a>
                                </div>
                            </div>
                        </nav>
                    </header>

                    <div id="content" className="site-content">
                        <div id="primary" className="content-area">
                            <div className="layout social">
                                <div className="container-fluid">
                                    <div className="row" style={{
                                        width: "75%",
                                        margin: "0 auto",
                                        marginTop: "80px"
                                    }}>
                                        <div className="col-lg-8 col-main">
                                            <main id="main" className="main-content">
                                                <div className="beehive-title-bar social">
                                                    <div className="title-bar-wrapper">
                                                        <div className="title-wrapper screen-reader-text">
                                                            <h1 className="title h3">Shop</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="shop-filters beehive-filters">
                                                    <div className="filter-wrapper">
                                                        <div className="search beehive-shop-search">
                                                            <form role="search" method="get" className="woocommerce-product-search" action="https://buzzinguniverse.com/">
                                                                <label className="screen-reader-text" for="woocommerce-product-search-field-0">Search for:</label>
                                                                <input 
                                                                    type="text" 
                                                                    name="search_location" 
                                                                    id="search_location" 
                                                                    placeholder="Search" 
                                                                    value={searchLocation}
                                                                    onChange={handleLocationChange}
                                                                />
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                                <header className="woocommerce-products-header"></header>
                                                <div className="woocommerce-notices-wrapper"></div>
                                                <p className="woocommerce-result-count"> Showing all 4 results</p>
                                                <form className="woocommerce-ordering" method="get">
                                                    <select name="orderby" className="orderby" aria-label="Shop order">
                                                        <option value="menu_order" selected="selected">Default sorting</option>
                                                        <option value="popularity">Sort by popularity</option>
                                                        <option value="rating">Sort by average rating</option>
                                                        <option value="date">Sort by latest</option>
                                                        <option value="price">Sort by price: low to high</option>
                                                        <option value="price-desc">Sort by price: high to low</option>
                                                    </select>
                                                    <input type="hidden" name="paged" value="1" />
                                                </form>
                                                <ul className="products columns-3">
                                                    {stores?.map((item, index) => {
                                                        return (
                                                            <li className="animate-item slideInUp beehive-post product type-product post-313 status-publish first instock product_cat-clothing-store product_cat-shop product_cat-t-shirts-clothing-store has-post-thumbnail shipping-taxable product-type-external" style={{ visibility: "visible" }}>
                                                                <div className="item-product">
                                                                    <div className="img-top">
                                                                        <div className="product-img">
                                                                            <img width="256" height="256" src={item?.image} />
                                                                        </div>
                                                                        <div className="hover-only">
                                                                            <a href="/product/t-shirt-wear-your-cosmic-apparel/" className="hover-overlay"></a>
                                                                            <ul className="product-actions">
                                                                            <li>
                                                                                    <a className="button product_type_external" data-product_id="313" data-product_sku="" aria-label="Buy on the WordPress swag store!" rel="nofollow">{item?.title}</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="" data-quantity="1" className="button product_type_external" data-product_id="313" data-product_sku="" aria-label="Buy on the WordPress swag store!" rel="nofollow">{item?.description}</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="/product/t-shirt-wear-your-cosmic-apparel/" className="view_cart_button" aria-label="View Product">View Product</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="product-info">
                                                                    <h2 className="woocommerce-loop-product__title">
                                                                            <a href="/product/t-shirt-wear-your-cosmic-apparel/">{item?.title}</a>
                                                                        </h2>
                                                                        <h2 className="woocommerce-loop-product__title">
                                                                            <a href="/product/t-shirt-wear-your-cosmic-apparel/">{item?.description}</a>
                                                                        </h2>
                                                                        <span className="price">
                                                                            <span className="woocommerce-Price-amount amount">
                                                                                <bdi>
                                                                                    <span className="woocommerce-Price-currencySymbol">$</span>{item?.price}</bdi>
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>

                                                <div style={{ width: '100%', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {data?.next_page_url != null &&
                                                        <input disabled={loading} onClick={() => fetchstores(pageNumber + 1)} type="button" name="fetch-records" className="button" value={loading ? 'Loading...' : 'Shwo More'} />
                                                    }
                                                </div>
                                            </main>

                                        </div>

                                        <div className="col-lg-4 col-aside">
                                            <aside id="default_sidebar" className="widget-area sidebar-widget-area sticky-sidebar">
                                                <nav className="sidebar-nav-menu">
                                                    <ul id="menu-sidebar-menu" className="aside-navbar">
                                                        <li id="menu-item-473" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-473">
                                                            <a href="/">Home</a>
                                                        </li>
                                                        <li id="menu-item-114" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-114">
                                                            <a href="/about-us/">About Us</a>
                                                        </li>
                                                        <li id="menu-item-113" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-113">
                                                            <a href="/faqs/">FAQs</a>
                                                        </li>
                                                        <li id="menu-item-116" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-116">
                                                            <a href="/article/">Blog</a>
                                                        </li>
                                                        <li id="menu-item-112" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-112">
                                                            <a href="/contact/">Contact</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </aside>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className="js-hiraku-offcanvas" aria-hidden="true" aria-labelledby="hiraku-offcanvas-btn-1" id="js-hiraku-offcanvas-1" aria-label="close">
                    <div id="navbar-account-sidebar" className="js-hiraku-offcanvas-sidebar js-hiraku-offcanvas-sidebar-right" role="navigation"></div>
                </div>


                <div id="buddy-chat-app" className="dash">
                    <div id="buddy-chat-buddies" className="collapsed">
                        <div className="buddy-chat-buddies__container">
                            <div className="header-container">
                                <div className="header-title">
                                    <span className="dashicons dashicons-format-chat"></span>
                                    <div className="window-title">
                                        <h5>Messenger</h5>
                                    </div>
                                </div>
                                <div className="dropd-group dropd-dr">
                                    <a className="dropd-control mute">
                                        <span className="dashicons dashicons-admin-generic"></span>
                                    </a>
                                    <div className="dropd-menu">
                                        <a className="dropd-item">Mute</a>
                                    </div>
                                </div>
                            </div>
                            <div className="vb vb-invisible">
                                <div className="buddy-chat-buddies__content vb-content">
                                    <ul className="buddy-chat-nav-tabs">
                                        <li className="item">
                                            <a className="item-link">Friends</a>
                                        </li>
                                        <li className="item">
                                            <a className="item-link">Groups</a>
                                        </li>
                                    </ul>
                                    <div id="buddy-list" className="bpc-tab-content">


                                    </div>
                                </div>
                                <div className="vb-dragger">
                                    <div className="vb-dragger-styler"></div>
                                </div>
                            </div>
                            <div id="buddy-chat-buddies__collapser" className="buddy-chat-buddies__collapser">
                                <div className="collapse-icon">
                                    <span className="dashicons dashicons-arrow-right-alt2"></span>
                                </div>
                                <div className="action-text">
                                    <h5>Collapse</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Shop;
