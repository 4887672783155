import React from 'react';
import BuzzPostComments from './BuzzPostComments';
import BuzzTextArea from './BuzzTextArea';
import BuzzButton from './BuzzButton';
import { X } from 'lucide-react';
import BuzzTimelineTitle from './BuzzTimelineTitle';

const BuzzPostModal = ({
	buzzImg,
	buzzComments,
	buzzBtnClick,
	buzzOnCommentChange,
	buzzCommentValue,
	buzzOnCloseModal,
	buzzPostTitle,
	buzzPostDate,
}) => {
	return (
		<div className='fixed top-0 left-0 z-50 grid w-full h-full grid-cols-4 p-10 buzzModal'>
			<div className='col-span-3 p-5 bg-gray-900 img-container'>
				<img className='h-[510px] rounded-md' src={buzzImg} alt='' />
			</div>
			<div className='relative p-4 bg-white comments'>
				<div className='flex justify-end w-full mb-2'>
					<span
						onClick={buzzOnCloseModal}
						className='flex items-center justify-center w-8 h-8 border rounded-full cursor-pointer'
					>
						<X size={18} className='' />
					</span>
				</div>
				<BuzzTimelineTitle
					buzzCreateDated={buzzPostDate}
					buzzOnTitleClick={() => {
						return;
					}}
					buzzTitle={buzzPostTitle}
				/>
				<BuzzPostComments
					buzzIsPostsFromModal={false}
					buzzPostComments={buzzComments}
				/>

				<div className='absolute left-1/2 -translate-x-1/2 w-[90%]  mt-6 create-coment bottom-5'>
					<BuzzTextArea
						value={buzzCommentValue}
						onChange={buzzOnCommentChange}
						rowsCount={3}
						classes={'border rounded-md'}
					/>
					<BuzzButton
						buzzBtnClick={buzzBtnClick}
						buzzIsPrimary={true}
						buzzBtnText={'Comment'}
					/>
				</div>
			</div>
		</div>
	);
};

export default BuzzPostModal;
