import React from 'react';

const BuzzTextArea = ({ name, value, onChange, rowsCount, classes }) => {
	return (
		<textarea
			name={name}
			rows={rowsCount}
			placeholder="What's new?"
			value={value}
			onChange={onChange}
			className={classes + ' w-full p-2 text-sm text-gray-400 outline-none'}
		/>
	);
};

export default BuzzTextArea;
