import React, { useCallback, useEffect, useState } from 'react';
// import "https://buzzinguniverse.iqspark.org/css_file/members.css"
import { Helmet } from 'react-helmet';
const Members = () => {
    const [members, setMembers] = useState([]);
    const [data, setDate] = useState()
    const [pageNumber, setPageNumber] = useState(1)
    const [loading, setLoading] = useState(false)
    const [searchLocation, setSearchLocation] = useState('');

    const debouncedSearch = useCallback(
        (() => {
            let timeoutId;
            return (searchValue) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setMembers([]);
                    setPageNumber(1);
                    fetchmembers(1, searchValue);
                }, 3000);
            };
        })(),
        []
    );

    const handleLocationChange = (e) => {
        const value = e.target.value;
        setSearchLocation(value);
        debouncedSearch(value);
    };

    const fetchmembers = async (page, search = '') => {
        setPageNumber(page)
        setLoading(true)
        try {
            const response = await fetch(`https://api.buzzinguniverse.iqspark.org/public/api/get-all-members?page=${page}${search ? `&search=${search}` : ''}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            if (data?.status == 200 || data?.success) {
                setMembers(prevMembers => [...prevMembers, ...data?.data?.data])
                setDate(data?.data)
            }
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchmembers(1)
    }, []);
    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://buzzinguniverse.iqspark.org/css_file/members.css"
                />
            </Helmet>
            <div id="beehive-page" class="site">
                <header id="sochead" class="site-header social-header user-nav-active">
                    <nav class="navbar beehive-navbar social fixed-top">
                        <div class="container">
                            <button class="beehive-toggler panel-toggler js-hiraku-offcanvas-btn" type="button" data-toggle-offcanvas="#js-hiraku-offcanvas-0" aria-expanded="false" aria-label="Menu" aria-controls="js-hiraku-offcanvas-0" id="hiraku-offcanvas-btn-0">
                                <span class="icon-bar bar1"></span>
                                <span class="icon-bar bar2"></span>
                                <span class="icon-bar bar3"></span>
                            </button>

                            <ul id="navbar-user" class="navbar-nav navbar-user">
                                <li class="mini-cart nav-item">
                                    <a href="/cart/" class="cart-contents nav-link" title="View Cart">
                                        <i class="uil-cart"></i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a href="/" class="nav-link login" data-toggle="modal" data-target="#login-modal">Login</a>
                                </li>
                                <li class="nav-item">
                                    <a href="/register" class="nav-link register">Register</a>
                                </li>
                            </ul>

                            <div id="beehive-ajax-search" class="beehive-ajax-search">
                                <form role="search" method="get" id="ajax-search-form" class="ajax-search-form form-inline" action="https://buzzinguniverse.com/">
                                    <div class="search-field">
                                        <i class="icon ion-android-search"></i>
                                        <input id="ajax-search-textfield" type="text" name="s" placeholder="search..." value="" autocomplete="off" required="" />
                                        <span class="beehive-loading-ring"></span>
                                    </div>
                                    <div class="search-button">
                                        <button type="submit" class="search-submit">
                                            <i class="icon ion-android-search"></i>
                                        </button>
                                    </div>
                                </form>
                                <div id="ajax-search-result"></div>
                            </div>
                            <div main-logo-header="">
                                <a class="navbar-brand" href="/">
                                    <img src="https://buzzinguniverse.com/wp-content/uploads/2023/06/809890-1.png" title="BuzzingUniverse- Login or Signup" alt="BuzzingUniverse- Login or Signup" class="default-logo" />
                                </a>
                            </div>
                        </div>
                    </nav>
                </header>

                <div id="content" style={{
                    width: "80%",
                    margin: "0 auto",
                    marginTop: "100px"
                }} class="site-content">
                    <div id="primary" class="content-area">
                        <div class="layout social">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-8 col-main">
                                        <main id="main" class="main-content">
                                            <div class="beehive-title-bar social">
                                                <div class="title-bar-wrapper">
                                                    <div class="title-wrapper screen-reader-text">
                                                        <h1 class="title h3">Members</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <article id="post-0" class="post-0 page type-page status-publish hentry beehive-post">
                                                <div class="entry-content clearfix">
                                                    <div id="buddypress" class="buddypress-wrap beehive bp-dir-hori-nav alignwide">
                                                        <nav class="members-type-navs main-navs bp-navs dir-navs " role="navigation" aria-label="Directory menu">
                                                            <ul class="component-navigation members-nav">
                                                                <li id="members-all" class="selected" data-bp-scope="all" data-bp-object="members">
                                                                    <a href="/members/">Active Members&nbsp; <span class="count">{data?.total}</span>
                                                                    </a>
                                                                </li>
                                                            </ul>

                                                        </nav>

                                                        <div class="screen-content">
                                                            <div class="subnav-filters filters no-ajax" id="subnav-filters">
                                                                <div class="subnav-search clearfix">
                                                                    <div class="dir-search members-search bp-search" data-bp-search="members">
                                                                        <form action="" method="get" class="bp-dir-search-form" id="dir-members-search-form" role="search">
                                                                            <label for="dir-members-search" class="bp-screen-reader-text">Search Members...</label>
                                                                            <input
                                                                                type="text"
                                                                                name="search_location"
                                                                                id="search_location"
                                                                                placeholder="Search"
                                                                                value={searchLocation}
                                                                                onChange={handleLocationChange}
                                                                            />
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                <div id="dir-filters" class="component-filters clearfix">
                                                                    <div id="members-order-select" class="last filter">
                                                                        <label class="bp-screen-reader-text" for="members-order-by">
                                                                            <span>Order By:</span>
                                                                        </label>
                                                                        <div class="select-wrap">
                                                                            <select id="members-order-by" data-bp-filter="members">
                                                                                <option value="active">Last Active</option>
                                                                                <option value="newest">Newest Registered</option>
                                                                                <option value="alphabetical">Alphabetical</option>
                                                                            </select>
                                                                            <span class="select-arrow" aria-hidden="true"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div id="members-dir-list" class="members dir-list" data-bp-list="members" >
                                                                <ul id="members-list" class="item-list members-list bp-list grid two">
                                                                    {members?.map((item, index) => {
                                                                        return (
                                                                            <li kay={index} class="item-entry animate-item slideInUp odd" data-bp-item-id="1395" data-bp-item-component="members" style={{ visibility: "visible" }}>
                                                                                <div class="list-wrap">
                                                                                    <div class="item-avatar">
                                                                                        <a href="/members/faizankhan7/">
                                                                                            <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-200.png" class="avatar user-1395-avatar avatar-200 photo" width="200" height="200" alt="Profile picture of Faizan" />
                                                                                        </a>
                                                                                        <div class="circle-color offline-red"></div>
                                                                                    </div>
                                                                                    <div class="item">
                                                                                        <div class="item-block">
                                                                                            <h4 class="list-title member-name">
                                                                                                <a href="/members/faizankhan7/">{item?.name}</a>
                                                                                            </h4>
                                                                                            <p class="item-meta last-activity mute">{item?.last_seen}</p>

                                                                                            <ul class="connections">
                                                                                                <li>
                                                                                                    <span class="count">0</span>
                                                                                                    <p class="mute">Friends</p>
                                                                                                </li>
                                                                                                <li>
                                                                                                    <span class="count">0</span>
                                                                                                    <p class="mute">Groups</p>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <p class="latest-update"></p>
                                                                                            <ul class=" members-meta action">
                                                                                                <li class="generic-button">
                                                                                                    <a href="/members/faizankhan7/">View Profile</a>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                <div style={{ width: '100%', marginTop: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    {data?.next_page_url != null &&
                                                                        <input disabled={loading} onClick={() => fetchmembers(pageNumber + 1)} type="button" name="fetch-records" className="button" value={loading ? 'Loading...' : 'Shwo More'} />
                                                                    }
                                                                </div>
                                                                {/* <div class="bp-pagination bottom" data-bp-pagination="upage">
                                                                    <div class="pag-count bottom">
                                                                        <p class="pag-data"> Viewing 1 - 20 of 32 active members </p>
                                                                    </div>
                                                                    <div class="bp-pagination-links bottom">
                                                                        <p class="pag-data">
                                                                            <span aria-current="page" class="page-numbers current">1</span>
                                                                            <a class="page-numbers" href="/members/?upage=2">2</a>
                                                                            <a class="next page-numbers" href="/members/?upage=2">→</a>
                                                                        </p>
                                                                    </div>
                                                                </div> */}
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </article>

                                        </main>

                                    </div>

                                    <div class="col-lg-4 col-aside">
                                        <aside id="members" class="widget-area sidebar-widget-area sticky-sidebar">
                                            <div id="bp_core_members_widget-2" class="widget clearfix widget_bp_core_members_widget buddypress widget">
                                                <h3 class="widgettitle">Members</h3>
                                                <div class="item-options" id="members-list-options">
                                                    <a href="/members/" id="newest-members">Newest</a>
                                                    <span class="bp-separator" role="separator">|</span>
                                                    <a href="/members/" id="recently-active-members" class="selected">Active</a>
                                                    <span class="bp-separator" role="separator">|</span>
                                                    <a href="/members/" id="popular-members">Popular</a>
                                                </div>
                                                <ul id="members-list" class="item-list" aria-live="polite" aria-relevant="all" aria-atomic="true">
                                                    <li class="vcard">
                                                        <div class="item-avatar">
                                                            <a href="/members/faizankhan7/" class="bp-tooltip" data-bp-tooltip="Faizan">
                                                                <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-50.png" class="avatar user-1395-avatar avatar-50 photo" width="50" height="50" alt="Profile picture of Faizan" />
                                                            </a>
                                                        </div>
                                                        <div class="item">
                                                            <div class="item-title fn">
                                                                <a href="/members/faizankhan7/">Faizan</a>
                                                            </div>
                                                            <div class="item-meta">
                                                                <span class="activity">2 hours ago</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="vcard">
                                                        <div class="item-avatar">
                                                            <a href="/members/bejan/" class="bp-tooltip" data-bp-tooltip="Bejan C.">
                                                                <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-50.png" class="avatar user-16-avatar avatar-50 photo" width="50" height="50" alt="Profile picture of Bejan C." />
                                                            </a>
                                                        </div>
                                                        <div class="item">
                                                            <div class="item-title fn">
                                                                <a href="/members/bejan/">Bejan C.</a>
                                                            </div>
                                                            <div class="item-meta">
                                                                <span class="activity">6 hours ago</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="vcard">
                                                        <div class="item-avatar">
                                                            <a href="/members/shazzy/" class="bp-tooltip" data-bp-tooltip="Shazzy">
                                                                <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-50.png" class="avatar user-1398-avatar avatar-50 photo" width="50" height="50" alt="Profile picture of Shazzy" />
                                                            </a>
                                                        </div>
                                                        <div class="item">
                                                            <div class="item-title fn">
                                                                <a href="/members/shazzy/">Shazzy</a>
                                                            </div>
                                                            <div class="item-meta">
                                                                <span class="activity">7 hours ago</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="vcard">
                                                        <div class="item-avatar">
                                                            <a href="/members/solojobs/" class="bp-tooltip" data-bp-tooltip="Solojobs Giana">
                                                                <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-50.png" class="avatar user-11-avatar avatar-50 photo" width="50" height="50" alt="Profile picture of Solojobs Giana" />
                                                            </a>
                                                        </div>
                                                        <div class="item">
                                                            <div class="item-title fn">
                                                                <a href="/members/solojobs/">Solojobs Giana</a>
                                                            </div>
                                                            <div class="item-meta">
                                                                <span class="activity">3 days ago</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="vcard">
                                                        <div class="item-avatar">
                                                            <a href="/members/b2023-_/" class="bp-tooltip" data-bp-tooltip="B2023-_">
                                                                <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatars/1/1700074345-bpthumb.png" class="avatar user-1-avatar avatar-50 photo" width="50" height="50" alt="Profile picture of B2023-_" />
                                                            </a>
                                                        </div>
                                                        <div class="item">
                                                            <div class="item-title fn">
                                                                <a href="/members/b2023-_/">B2023-_</a>
                                                            </div>
                                                            <div class="item-meta">
                                                                <span class="activity">3 days ago</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <input type="hidden" id="_wpnonce-members" name="_wpnonce-members" value="001b6c483f" />
                                                <input type="hidden" name="members_widget_max" id="members_widget_max" value="5" />
                                            </div>
                                            <nav class="sidebar-nav-menu">
                                                <ul id="menu-sidebar-menu" class="aside-navbar">
                                                    <li id="menu-item-473" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-473">
                                                        <a href="/">Home</a>
                                                    </li>
                                                    <li id="menu-item-114" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-114">
                                                        <a href="/about-us/">About Us</a>
                                                    </li>
                                                    <li id="menu-item-113" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-113">
                                                        <a href="/faqs/">FAQs</a>
                                                    </li>
                                                    <li id="menu-item-116" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-116">
                                                        <a href="/article/">Blog</a>
                                                    </li>
                                                    <li id="menu-item-112" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-112">
                                                        <a href="/contact/">Contact</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </aside>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </>
    );
};

export default Members;
