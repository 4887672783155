import React from 'react';

const BuzzTimelineTitle = ({
	buzzTitle,
	buzzOnTitleClick,
	buzzCreateDated,
}) => {
	return (
		<div>
			<strong
				className='block font-bold text-gray-800 cursor-pointer'
				onClick={buzzOnTitleClick}
			>
				{buzzTitle}
			</strong>
			<span className='block mt-1 text-xs font-thin text-gray-400'>
				{buzzCreateDated}
			</span>
		</div>
	);
};

export default BuzzTimelineTitle;
