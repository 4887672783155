import axiosInstance from '../API/axiosInstance';

export class ActivityPostsRepositr {
	// HANDLE FAVOURITE
	async handleFavouritePost(payload) {
		const response = await axiosInstance.post(
			'create-remove-post-favourite',
			payload,
		);
		return response.data;
	}

	// HANDLE LIKES
	async handleLikesDislikes(payload) {
		const response = await axiosInstance.post(
			'post-likes-and-dislikes',
			payload,
		);
		return response.data;
	}

	// SHARE POST
	async handleSharePost(id) {
		const response = await axiosInstance.post('share-post/' + id);
		return response.data;
	}

	// DELETE POS
	async DeletePost(id) {
		const response = await axiosInstance.delete('delete-post/' + id);
		return response.data;
	}

	// CREATE POST
	async CreatePost(payload) {
		const response = await axiosInstance.post('create-post', payload);
		return response.data;
	}

	// GET POSTS
	async GetPosts() {
		const response = await axiosInstance.get('get-all-posts');
		return response.data;
	}

	// GET USERS
	async GetUsers() {
		const reponse = await axiosInstance.get('get-all-users');
		return reponse.data;
	}

	// GET MENTION POSTS
	async GetMentionPosts() {
		const response = await axiosInstance.get('get-my-mentioned-posts');
		return response.data;
	}

	// CREATE COMMENT
	async CreatePostComment(payload) {
		const response = await axiosInstance.post('add-post-comment', payload);
		return response.data;
	}
}
