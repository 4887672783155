import { User2 } from 'lucide-react';
import { useState } from 'react';
import { CommentsRepositry } from '../data-domain/Repositry/CommentsRepo';
import { CommentsUsecases } from '../data-domain/Usecases/CommentsUseCases';
import BuzzInputfields from './BuzzInputfields';
import { toast } from 'react-toastify';

const BuzzPostComments = ({
	buzzPostComments,
	buzzOnDelete,
	buzzOnEditRefresh,
	buzzIsPostsFromModal,
}) => {
	//? STATES
	const [isEditComment, setIsEditComment] = useState(false);
	const [selectedComment, setSelectedComment] = useState(null);

	//? INSTANCE
	const commentsRepo = new CommentsRepositry();
	const commentsUsesCases = new CommentsUsecases(commentsRepo);

	//? HANDLERS

	// UPDATE COMMENT
	const updateCommets = async () => {
		try {
			const formData = new FormData();
			formData.append('comment', selectedComment.comment);
			const data = await commentsUsesCases.updateComment(
				selectedComment.id,
				formData,
			);
			toast.success(data.message);
		} catch (error) {
			console.log(error);
		}
	};

	// DELETE COMMENT
	const handleDeleteComment = async (id) => {
		try {
			await commentsUsesCases.deleteComment(id);
		} catch (error) {}
	};

	return (
		<div className='mt-5'>
			{buzzPostComments.map((postComment) => (
				<div className='commentContainer'>
					{/* USER INFO */}
					<div className='flex items-center gap-3 mb-5 user-info'>
						<span className='flex items-center justify-center rounded-full w-9 h-9 bg-slate-100'>
							<User2 size={18} />
						</span>
						<div className='flex items-center gap-2 text-sm'>
							<strong className='text-gray-800'>
								{postComment.user.username}
							</strong>
							<span className='text-gray-500'>{postComment.created_at}</span>
						</div>
					</div>

					{/* COMMENT DETAILS */}
					<div className='mb-3 comment-details'>
						{selectedComment?.id === postComment.id && isEditComment ? (
							<div className=''>
								<div className='p-3 rounded-md bg-slate-100'>
									<BuzzInputfields
										buzzOnChange={(e) =>
											setSelectedComment((prev) => ({
												...prev,
												comment: e.target.value,
											}))
										}
										buzzValue={selectedComment.comment}
									/>
								</div>

								<div className='flex items-center gap-2 mt-2 text-sm text-gray-700 commen-actions'>
									<span
										onClick={() => {
											updateCommets();
											buzzOnEditRefresh(true);
										}}
										className='cursor-pointer'
									>
										Save
									</span>
									<span
										onClick={() => setIsEditComment(false)}
										className='cursor-pointer'
									>
										Cancel
									</span>
								</div>
							</div>
						) : (
							<div className='relative'>
								<div className='p-3 text-sm rounded-full bg-slate-100'>
									{postComment.comment}
								</div>
								{buzzIsPostsFromModal && (
									<div className='flex items-center gap-2 mt-2 text-sm text-gray-700 commen-actions'>
										<span
											onClick={() => {
												handleDeleteComment(postComment.id);
												buzzOnDelete(true);
											}}
											className='cursor-pointer'
										>
											Delete
										</span>
										<span
											onClick={() => {
												setIsEditComment(true);
												setSelectedComment(postComment);
											}}
											className='cursor-pointer'
										>
											Edit
										</span>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default BuzzPostComments;
