import React from 'react';

const BuzzMentionedUsers = ({
	buzzMentionedUsers,
	buzzStyles,
	buzzSelectUser,
	buzzClassess,
}) => {
	return (
		<ul
			className={
				buzzClassess +
				' w-full h-32 p-2 bg-white overflow-y-scroll text-xs border rounded-md max-h-32'
			}
		>
			{buzzMentionedUsers.map((item, index) => (
				<li
					key={index}
					onClick={() => buzzSelectUser(item)}
					className='hover:bg-slate-50'
					style={buzzStyles}
				>
					{item.username}
				</li>
			))}
		</ul>
	);
};

export default BuzzMentionedUsers;
