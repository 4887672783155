import React from 'react';

const BuzzTimelineImageUser = ({ buzzImgSrc, buzzIsNestedImg }) => {
	return (
		<img
			className={`object-cover ${
				buzzIsNestedImg ? 'w-10 h-10' : 'w-12 h-12'
			} rounded-full`}
			src={
				buzzImgSrc
					? buzzImgSrc
					: 'https://static.vecteezy.com/system/resources/previews/036/594/092/non_2x/man-empty-avatar-photo-placeholder-for-social-networks-resumes-forums-and-dating-sites-male-and-female-no-photo-images-for-unfilled-user-profile-free-vector.jpg'
			}
			alt='wait'
		/>
	);
};

export default BuzzTimelineImageUser;
