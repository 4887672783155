import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AllAdvertsLising from './components/allAdvertsListing';
import ManageAdverts from './components/manageAdvert';
// import '../src/css/alljobs.css'
import Advsubmit from './Advsubmit';

const AllAdverts = () => {
	const [activeTab, setActiveTab] = useState('All jobs');

	const location = useLocation();
	const { state } = location;

	console.log(state);

	useEffect(() => {
		if (state == 'adverts') {
			setActiveTab('All adverts');
		} else if (state == 'Categories') {
			setActiveTab('Categories');
		} else if (state == 'manage') {
			setActiveTab('Manage');
		} else if (state == 'Submit') {
			setActiveTab('Submit');
		} else {
			setActiveTab('All adverts');
		}
	}, []);

	const handleTabClick = (tabName) => {
		setActiveTab(tabName);
	};

	return (
		<>
			<div
				className='header-list-sec'
				style={{ justifyContent: 'center', width: '90%' }}
			>
				<p
					className={activeTab === 'All adverts' ? 'active_profile_act' : ''}
					onClick={() => handleTabClick('All adverts')}
				>
					All Adverts
				</p>
				<p
					className={activeTab === 'Categories' ? 'active_profile_act' : ''}
					onClick={() => handleTabClick('Categories')}
				>
					Categories
				</p>
				<p
					className={activeTab === 'Manage' ? 'active_profile_act' : ''}
					onClick={() => handleTabClick('Manage')}
				>
					Manage
				</p>
				<p
					className={activeTab === 'Submit' ? 'active_profile_act' : ''}
					onClick={() => handleTabClick('Submit')}
				>
					Submit
				</p>
			</div>
			{/* Conditionally render the content based on the active tab */}
			<div className='tab-content'>
				{activeTab === 'All adverts' && (
					<div style={{ width: '90%' }} className='actity-tabs'>
						<AllAdvertsLising />
					</div>
				)}
				{activeTab === 'Categories' && (
					<div style={{ width: '90%' }} className='actity-tabs'>
						<p style={{ textAlign: 'center' }}>No adverts categories found.</p>
					</div>
				)}
				{activeTab === 'Manage' && (
					<div style={{ width: '90%' }} className='actity-tabs'>
						<ManageAdverts />
					</div>
				)}
				{activeTab === 'Submit' && (
					<div style={{ width: '90%' }} className='actity-tabs'>
						<Advsubmit />
					</div>
				)}
			</div>
		</>
	);
};

export default AllAdverts;
