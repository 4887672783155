import React from 'react';

const BuzzInputfields = ({
	buzzValue,
	buzzName,
	buzzOnChange,
	buzzType,
	buzzClases,
}) => {
	return (
		<input
			type={buzzType || 'text'}
			id='first_name'
			class={
				buzzClases +
				' bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg   block w-full p-2.5 outline-none'
			}
			placeholder='John'
			value={buzzValue}
			onChange={buzzOnChange}
		/>
	);
};

export default BuzzInputfields;
