import React from 'react';

const BuzzTabs = ({ buzzTabs, buzzTabActiveIndex, buzzOnTabChange }) => {
	return (
		<ul className='relative flex items-center gap-5 mb-2 text-sm border-b border-gray-200'>
			{buzzTabs.map((tab, index) => (
				<li
					key={index}
					onClick={() => buzzOnTabChange(index)}
					className={`pb-3 cursor-pointer ${
						buzzTabActiveIndex === index
							? 'text-blue-950 font-medium border-b-2 border-blue-950'
							: ''
					}`}
				>
					{tab.label}
				</li>
			))}
		</ul>
	);
};

export default BuzzTabs;
