import axiosInstance from '../API/axiosInstance';

export class CommentsRepositry {
	// UPDATE COMMENT
	async updateComment(id, payload) {
		const response = await axiosInstance.post(
			'edit-post-comment/' + id,
			payload,
		);
		return response.data;
	}

	// DELETE COMMENT
	async DeleteComment(id) {
		const response = await axiosInstance.delete('delete-post-comment/' + id);
		return response.data;
	}

	// CREATE COMMENT
	async CreateComment(payload) {
		const response = await axiosInstance.post('add-post-comment', payload);
		return response.data;
	}
}
