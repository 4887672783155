import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../assets/09890-300x300.png";

const SubHeader = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [login, setLogin] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [remember, setRemember] = useState(false);
    const [progress, setProgress] = useState(0);

    const headerStyle = {
        backgroundColor: '#000',
        padding: '10px 20px',
    };

    const navStyle = {
        display: 'flex',
        justifyContent: 'center',
    };

    const navListStyle = {
        listStyleType: 'none',
        display: 'flex',
        margin: 0,
        padding: 0,
    };

    const navItemStyle = {
        position: 'relative',
        padding: '10px 20px',
        color: 'white',
        cursor: 'pointer',
    };

    const dropdownContentStyle = (isVisible) => ({
        display: isVisible ? 'block' : 'none',
        position: 'absolute',
        backgroundColor: '#333',
        minWidth: '150px',
        zIndex: 1,
        marginTop: '10px',
        padding: '10px 0',
    });

    const dropdownItemStyle = {
        padding: '10px 20px',
        color: 'white',
        cursor: 'pointer',
    };

    const lastNavItemStyle = {
        marginLeft: 'auto',
    };

    const handleMouseEnter = (index) => {
        setDropdownVisible(index);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(null);
    };

    const handleNavigateTo = (path) => {
        if (!path.startsWith('/')) {
            path = `/${path}`;
        }
        navigate(path);
    };

    const handleNavigateTo1 = (path) => {
        console.log(path)
        navigate('/jobes', { state: path });
    }

    const handleNavigateTo2 = (path) => {
        console.log(path)
        navigate('/adverts', { state: path });
    }

    // username
    useEffect(() => {
        if (localStorage.getItem("username")) {
            setLogin(true);
        }
    }, []);


    const handleLogout = () => {
        localStorage.removeItem('login');
        localStorage.removeItem('Logtoken');
        localStorage.removeItem('username');
        sessionStorage.removeItem('login');
        navigate('/');
    };

    const handleLoginClick = () => {
        setShowLoginPopup(true);
    };

    const handleInputChange = (e) => {
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        });

        let newProgress = 0;
        if (loginData.email) {
            newProgress += 50;
        }
        if (loginData.password) {
            newProgress += 50;
        }
        setProgress(newProgress);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!loginData.email) {
            newErrors.email = 'Email is required';
        }
        if (!loginData.password) {
            newErrors.password = 'Password is required';
        } else if (loginData.password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (validateForm()) {
            try {
                const formData = new FormData();
                formData.append('email', loginData?.email);
                formData.append('blue_key', loginData?.password);
                const response = await fetch('https://api.buzzinguniverse.iqspark.org/public/api/login', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                    },
                    body: formData
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Login successful:', data);

                    // Store user data
                    localStorage.setItem("username", JSON.stringify(data));
                    localStorage.setItem("Logtoken", data.token);

                    if (remember) {
                        // If "Remember Me" is checked, persist login with localStorage
                        localStorage.setItem("login", true);
                    } else {
                        // If "Remember Me" is not checked, store login in sessionStorage
                        sessionStorage.setItem("login", true);
                    }

                    // Navigate to activity page
                    window.location.reload()
                } else {
                    const data = await response.json();
                    console.log(data)
                }
            } catch (error) {
                console.error('Error:', error);
            }
            setLoading(false);
        }
    };

    const handleRememberChange = (e) => {
        setRemember(e.target.checked);
    };

    const popupStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#000',
        padding: '30px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
        zIndex: 1000,
        width: '300px',
        color: 'black'
    };

    const inputStyle = {
        display: 'block',
        margin: '10px 0',
        padding: '12px',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        border: 'none',
        borderRadius: '4px',
        color: 'black',
        fontSize: '14px'
    };

    const buttonStyle = {
        width: '100%',
        padding: '12px',
        backgroundColor: '#333',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px'
    };

    const errorStyle = {
        color: 'red',
        fontSize: '12px',
        marginBottom: '10px'
    };

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowLoginPopup(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            <header style={headerStyle}>
                <nav style={navStyle}>
                    <ul style={navListStyle}>
                        <li style={navItemStyle} onClick={() => handleNavigateTo('/')}>Home</li>
                        <li style={navItemStyle} onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave}>
                            Network
                            <ul className="dropdown-content" style={dropdownContentStyle(dropdownVisible === 0)}>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('companies')}>Companies</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('groups')}>Groups</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('members')}>Members</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('videos')}>Videos</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('images')}>Photos</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('forms')}>Forums</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('submitarticle')}>Submit Article</li>
                            </ul>
                        </li>
                        <li style={navItemStyle} onMouseEnter={() => handleMouseEnter(1)} onMouseLeave={handleMouseLeave}>
                            Jobs
                            <ul className="dropdown-content" style={dropdownContentStyle(dropdownVisible === 1)}>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo1('Jobs')}>All Jobs</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo1('Categories')}>Job Categories</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo1('Submit')}>Submit Job</li>
                            </ul>
                        </li>
                        <li style={navItemStyle} onMouseEnter={() => handleMouseEnter(2)} onMouseLeave={handleMouseLeave}>
                            Classifieds
                            <ul className="dropdown-content" style={dropdownContentStyle(dropdownVisible === 2)}>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo2('All Adverts')}>All Adverts</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo2('Advert Categories')}>Advert Categories</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo2('Submit Advert')}>Submit Advert</li>
                            </ul>
                        </li>
                        <li style={navItemStyle} onMouseEnter={() => handleMouseEnter(3)} onMouseLeave={handleMouseLeave}>
                            Pages
                            <ul className="dropdown-content" style={dropdownContentStyle(dropdownVisible === 3)}>
                                <li style={dropdownItemStyle}>Subscriptions</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('faq')}>FAQs</li>
                            </ul>
                        </li>
                        <li onClick={() => handleNavigateTo('Text')} style={navItemStyle}>Text</li>
                        <li style={{
                            position: 'relative',
                            padding: '10px 20px',
                            color: 'white',
                            cursor: 'pointer',
                        }} onMouseEnter={() => handleMouseEnter(6)} onMouseLeave={handleMouseLeave}>
                            Store
                            <ul className="dropdown-content" style={dropdownContentStyle(dropdownVisible === 6)}>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('shop')}>All Stores</li>
                                <li style={dropdownItemStyle} onClick={() => handleNavigateTo('submit-store')}>Create Store</li>
                            </ul>
                        </li>
                        <li

                            onClick={() => handleNavigateTo('Questions')} style={navItemStyle}>Questions</li>

                        {login ? <li style={{ ...navItemStyle, ...lastNavItemStyle }} onClick={() => handleLogout()}>Logout</li> : <><li style={{ ...navItemStyle, ...lastNavItemStyle }} onClick={handleLoginClick}>Login</li>
                            <li style={navItemStyle} onClick={() => handleNavigateTo('/register')}>Register</li></>}
                    </ul>
                </nav>
            </header>

            {showLoginPopup && (
                <div ref={popupRef} style={popupStyle}>
                    <form onSubmit={handleSubmit}>
                        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
                            <img style={{ width: "50px", height: "50px" }} src={logo} alt="Logo" />

                        </h2>
                        <div id="login-progress" className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100" style={{ width: `${progress}%` }}>
                                <span className="percent">{progress}%</span>
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <input
                                type="text"
                                name="email"
                                placeholder="Username"
                                style={inputStyle}
                                value={loginData.email}
                                onChange={handleInputChange}
                            />
                            {errors.email && <div style={errorStyle}>{errors.email}</div>}

                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                style={inputStyle}
                                value={loginData.password}
                                onChange={handleInputChange}
                            />
                            {errors.password && <div style={errorStyle}>{errors.password}</div>}
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                            <input
                                type="checkbox"
                                id="remember"
                                style={{ marginRight: '8px' }}
                                checked={remember}
                                onChange={handleRememberChange}
                            />
                            <label htmlFor="remember">Remember</label>
                            <a href="#" style={{ marginLeft: 'auto', color: '#3498db', textDecoration: 'none' }}>
                                Lost blue key?
                            </a>
                        </div>

                        {errors.submit && <div style={errorStyle}>{errors.submit}</div>}

                        <button type="submit" style={buttonStyle}>{loading ? 'Loading...' : 'Login'}</button>
                    </form>
                </div>
            )}
        </>
    );
};

export default SubHeader;
