import React from 'react';

const BuzzButton = ({
	buzzBtnText,
	buzzBtnClick,
	buzzIsBtnSmall,
	buzzIsPrimary,
}) => {
	return (
		<button
			onClick={buzzBtnClick}
			className={`text-sm px-6  rounded-full ${
				buzzIsPrimary
					? 'btn-bg hover:bg-red-300'
					: 'bg-transparent text-blue-950 hover:underline text-sm'
			}  ${buzzIsBtnSmall ? 'px-4 py-1' : 'px-2 py-2'} `}
		>
			{buzzBtnText}
		</button>
	);
};

export default BuzzButton;
