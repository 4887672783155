import React, { useState, useEffect, useRef } from 'react';
import { Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {
	BuzzButton,
	BuzzInputfields,
	BuzzMentionedUsers,
	BuzzPostActions,
	BuzzPostComments,
	BuzzTimelineImageUser,
	BuzzTimelineTitle,
} from '../ibrar-components';
import { EllipsisVertical, Heart, Trash2, User2 } from 'lucide-react';
import { CommentsRepositry } from '../data-domain/Repositry/CommentsRepo';
import { CommentsUsecases } from '../data-domain/Usecases/CommentsUseCases';
import { ActivityPostsRepositr } from '../data-domain/Repositry/AcitvityPostRepo';
import { ActivityPostsUsecases } from '../data-domain/Usecases/ActivityPostsUsecases';
const Timeline = ({
	posts,
	refreshPost,
	SetShowPop,
	SetDataPop,
	removePost,
	mentionesUsers,
}) => {
	//? STATES
	const [post, seetPosts] = useState([]);
	const [showComment, SetshowComment] = useState(false);
	const [showCommentid, SetshowCommentid] = useState('');
	const [comment_com, SetComment_com] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	const [info, setInfo] = useState([]);
	const [dropdownItems, setDropdownItems] = useState([]);
	const [mention_email, setMention_email] = useState('');
	const [filteredItems, setFilteredItems] = useState(mentionesUsers);

	const [selectedItem, setSeletectedItem] = useState(null);
	const [postActions, setPostActions] = useState(false);

	//? HOOKS

	const navigate = useNavigate();
	const excludedRef = useRef(null);

	//? INSTANCES
	const commentRepo = new CommentsRepositry();
	const activityPostsRepo = new ActivityPostsRepositr();
	const commentUseCases = new CommentsUsecases(commentRepo);
	const activityPostsUsecases = new ActivityPostsUsecases(activityPostsRepo);

	//?HANDLERS

	document.body.addEventListener('click', (event) => {
		if (excludedRef.current && !excludedRef.current.contains(event.target)) {
			setPostActions(false);
			setSeletectedItem(null);
		}
	});

	useEffect(() => {
		seetPosts(posts);
		var localinfo1 = localStorage.getItem('userinfo');
		var localinfo = JSON.parse(localinfo1);

		setInfo(localinfo);
	}, [posts]);

	const handleNavigateTo = (id) => {
		navigate(`/getProfile/${id}`);
	};

	const handleDeletePost = async (data) => {
		try {
			const response = await activityPostsUsecases.handleDeletePost(data);
			toast.success(response.message || 'Post deleted successfully');
			removePost(data);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const handleSubmitfav = async (postid) => {
		var userInfo;
		var userdata = localStorage.getItem('username');
		// console.log(userdata)
		if (userdata) {
			userInfo = JSON.parse(userdata);
		}

		const formData = new FormData();
		formData.append('user_id', userInfo.data.id);
		formData.append('post_id', postid);

		try {
			const response = await activityPostsUsecases.handleFavouritePost(
				formData,
			);
			toast.success(response.message);
			refreshPost(true);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const handleSubmitLike = async (data) => {
		const formData = new FormData();
		formData.append('post_id', data);
		formData.append('impression_type', 1);

		try {
			const response = await activityPostsUsecases.handleLikesDislikes(
				formData,
			);
			toast.success(response.message);
			refreshPost(true);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const RefrehPostsOnDeleteCommnet = (data) => {
		refreshPost(data);
	};
	const RefreshPostsOnUpdateComment = (data) => {
		refreshPost(data);
	};

	const handleSubmitCommit = async (data) => {
		try {
			const formData = new FormData();
			formData.append('post_id', data);
			formData.append('comment', comment_com);
			formData.append('mention_email', mention_email);
			const res = await commentUseCases.createComment(formData);
			toast.success(res.message);
			refreshPost(true);
			SetComment_com('');
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const selectItem = (item) => {
		SetComment_com((prev) => prev.replace(/@\w*$/, `@${item.username} `));
		setMention_email(item.email);
		setShowDropdown(false);
	};

	const handleInputChange = (e) => {
		const value = e.target.value;
		SetComment_com(value);

		const atIndex = value.lastIndexOf('@');
		const hasSpace = value.includes(' ');

		if (atIndex !== -1 && !hasSpace) {
			// Get the text after the "@" character
			const searchText = value.slice(atIndex + 1);

			// Filter items based on the text after "@"
			const filtered = dropdownItems.filter((item) =>
				item.username.toLowerCase().startsWith(searchText.toLowerCase()),
			);
			setFilteredItems(filtered);

			// Show dropdown only if we have search text and filtered items
			setShowDropdown(filtered.length > 0);
		} else {
			setShowDropdown(false); // Hide dropdown if no "@" or space encountered
		}
	};

	const handleSubmitShare = async (id) => {
		try {
			const response = await activityPostsUsecases.handleSharePost(id);
			toast.success(response.message);

			refreshPost(true);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};
	return (
		<>
			<div className='relative main-box-timeline'>
				{post.map((item, index) => (
					<div key={index} className='div-box'>
						<Box className='flex justify-between gap-5 mb-3 '>
							<div className='flex items-center gap-4'>
								<BuzzTimelineImageUser
									buzzImgSrc={info?.data?.user_information?.profile_image}
								/>

								<BuzzTimelineTitle
									buzzCreateDated={item.created_at}
									buzzOnTitleClick={() => {
										handleNavigateTo(item.user.id);
									}}
									buzzTitle={item?.title}
								/>
							</div>

							<div className='relative h-9'>
								<span
									ref={excludedRef}
									onClick={(e) => {
										e.stopPropagation();
										setSeletectedItem(item);
										setPostActions(true);
									}}
									className='flex items-center justify-center border rounded-full cursor-pointer hover:bg-slate-50 w-9 h-9'
								>
									<EllipsisVertical size={18} />
								</span>

								{item.id === selectedItem?.id && postActions && (
									<ul className='absolute z-50 top-[100%] right-0 p-3 border rounded-md bg-white'>
										<li
											onClick={() => handleDeletePost(item.id)}
											className='flex items-center gap-2 px-2 py-1 text-sm rounded-sm cursor-pointer hover:bg-slate-50'
										>
											<Trash2 size={16} /> Delete
										</li>
										<li
											onClick={() => handleSubmitfav(item.id)}
											className='flex items-center gap-2 px-2 py-1 text-sm rounded-sm cursor-pointer hover:bg-slate-50'
										>
											<Heart size={16} /> Favourite
										</li>
									</ul>
								)}
							</div>
						</Box>
						{item.original_post && (
							<Box className='flex items-center gap-3 mb-5 ml-10'>
								{item.original_post.user_information?.profile_image && (
									<BuzzTimelineImageUser
										buzzImgSrc={
											item.original_post.user_information?.profile_image
										}
										buzzIsNestedImg={true}
									/>
								)}
								<Box>
									<BuzzTimelineTitle
										buzzOnTitleClick={() => {
											handleNavigateTo(item.original_post.id);
										}}
										buzzCreateDated={item.created_at}
										buzzTitle={item?.original_post.name}
									/>
								</Box>
							</Box>
						)}
						<div className=' post-mainbox'>
							<Box>
								{item?.images.length > 0 && (
									<div className='av-Box-img' style={{ marginBottom: '15px' }}>
										<img
											style={{ cursor: 'pointer' }}
											onClick={() => {
												SetShowPop(true);
												SetDataPop(item);
											}}
											src={item?.images[0]?.image}
											alt='wait'
										/>
									</div>
								)}
								<Typography variant='body2' color='textSecondary'>
									{item.description}
								</Typography>
							</Box>
							<BuzzPostActions
								buzzOnHandleLike={() => handleSubmitLike(item.id)}
								buzzLikeCounts={item.likes_count}
								buzzCommentsCount={item.comments.length}
								buzzHandleShare={() => handleSubmitShare(item.id)}
								buzzToggleComment={() => {
									SetshowComment(!showComment);
									SetshowCommentid(item.id);
								}}
							/>

							{showComment && showCommentid === item.id && (
								<>
									<BuzzPostComments
										buzzOnDelete={RefrehPostsOnDeleteCommnet}
										buzzPostComments={item.comments}
										buzzOnEditRefresh={RefreshPostsOnUpdateComment}
									/>

									<div className='flex gap-3 w-[90%] ml-auto relative'>
										<span className='flex items-center justify-center rounded-full w-11 h-11 bg-slate-100'>
											<User2 size={18} />
										</span>

										<BuzzInputfields
											buzzOnChange={handleInputChange}
											buzzValue={comment_com}
											buzzClases={'!rounded-full'}
										/>

										{showDropdown && (
											<BuzzMentionedUsers
												buzzClassess={'absolute left-0 top-[100%]'}
												buzzSelectUser={selectItem}
												buzzMentionedUsers={filteredItems}
												buzzStyles={styles.dropdownItem}
											/>
										)}
									</div>
									<div className='mt-5 comment-btns'>
										<BuzzButton
											buzzIsBtnSmall={true}
											buzzIsPrimary={true}
											buzzBtnText={'Post'}
											buzzBtnClick={() => handleSubmitCommit(item.id)}
										/>
										<BuzzButton
											buzzIsBtnSmall={true}
											buzzIsPrimary={false}
											buzzBtnText={'Cancel'}
											buzzBtnClick={() => SetshowComment(false)}
										/>
									</div>
								</>
							)}
						</div>
					</div>
				))}
			</div>
			<ToastContainer />
		</>
	);
};
const styles = {
	dropdown: {
		position: 'absolute',
		top: '100%',
		left: 0,
		width: '300px',
		border: '1px solid #ccc',
		backgroundColor: 'white',
		zIndex: 1,
		marginTop: '-45px',
		marginLeft: '15%',
	},
	dropdownList: {
		listStyle: 'none',
		margin: 0,
		padding: 0,
	},
	dropdownItem: {
		padding: '10px',
		cursor: 'pointer',
	},
	dropdownItemHover: {
		backgroundColor: '#f0f0f0',
	},
};
export default Timeline;
