import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';

function CreateJob() {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        originalPrice: '',
        discountPrice: '',
    });
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formData.title.trim()) newErrors.title = 'Title is required';
        if (!formData.description.trim()) newErrors.description = 'Description is required';
        if (!formData.originalPrice) newErrors.originalPrice = 'Original price is required';
        if (!formData.discountPrice) newErrors.discountPrice = 'Discount price is required';
        if (!image) newErrors.image = 'Image is required';
        
        if (parseFloat(formData.discountPrice) >= parseFloat(formData.originalPrice)) {
            newErrors.discountPrice = 'Discount price must be less than original price';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {
            setImage(file);
        } else {
            toast.error('Please upload a valid image file');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const formDataToSubmit = new FormData();
        formDataToSubmit.append('title', formData.title);
        formDataToSubmit.append('description', formData.description);
        formDataToSubmit.append('price', formData.originalPrice);
        formDataToSubmit.append('discount_price', formData.discountPrice);
        formDataToSubmit.append('image', image);

        var bearertoken = localStorage.getItem("Logtoken")
        setLoading(true)
        try {
            const response = await fetch('https://api.buzzinguniverse.iqspark.org/public/api/stores', {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + bearertoken,
                    'Accept': 'application/json',
                },
                body: formDataToSubmit,
            });

            setLoading(false)
            const data = await response.json();
            if (data.status == 200 || data?.success) {
                toast.success("store submitted Successfully!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                window.location.reload();
            }
            else {
                throw new Error('Failed to post job');
            }
        } catch (error) {
            setLoading(false)
            console.error('Error:', error);
            throw new Error(JSON.stringify(error));
        }
    };


    const handleEditorChange = (content) => {
        setFormData((prev) => ({
            ...prev,
            description: content,
        }));
    };
    return (
        <>
            <ToastContainer />
            <Helmet>
                <link rel="stylesheet" href="https://buzzinguniverse.iqspark.org/css_file/CreateJob.css" />
            </Helmet>
            <article className="post-469 page type-page status-publish hentry beehive-post" 
                     style={{ width: "70%", margin: "100px auto 0" }}>
                <form onSubmit={handleSubmit} className="create-job-form">
                    <h2>Create New Advertisement</h2>
                    
                    <div className="form-group">
                        <label>Title*</label>
                        <input
                            type="text"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            className={errors.title ? 'error' : ''}
                        />
                        {errors.title && <span className="error-message">{errors.title}</span>}
                    </div>
                    <fieldset className="fieldset-job_description fieldset-type-wp-editor">
                                                        <label htmlFor="job_description">Description</label>
                                                        <div className="field required-field">
                                                            <Editor
                                                                apiKey="6n6085y2zxnpx1z72tdi0n1cqo5x7zl2zv3z5xtxikdg5oo6" // Get your free API key at https://www.tiny.cloud/
                                                                value={formData.description}
                                                                init={{
                                                                    height: 400,
                                                                    menubar: false,
                                                                    plugins: [
                                                                        'advlist autolink lists link image charmap print preview anchor',
                                                                        'searchreplace visualblocks code fullscreen',
                                                                        'insertdatetime media table paste code help wordcount'
                                                                    ],
                                                                    toolbar:
                                                                        'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help'
                                                                }}
                                                                onEditorChange={handleEditorChange}
                                                            />
                                                        </div>
                                                    </fieldset>

                    <div className="form-row">
                        <div className="form-group half">
                            <label>Original Price*</label>
                            <input
                                type="number"
                                name="originalPrice"
                                value={formData.originalPrice}
                                onChange={handleInputChange}
                                min="0"
                                step="0.01"
                                className={errors.originalPrice ? 'error' : ''}
                            />
                            {errors.originalPrice && <span className="error-message">{errors.originalPrice}</span>}
                        </div>

                        <div className="form-group half">
                            <label>Discount Price*</label>
                            <input
                                type="number"
                                name="discountPrice"
                                value={formData.discountPrice}
                                onChange={handleInputChange}
                                min="0"
                                step="0.01"
                                className={errors.discountPrice ? 'error' : ''}
                            />
                            {errors.discountPrice && <span className="error-message">{errors.discountPrice}</span>}
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Upload Image*</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className={errors.image ? 'error' : ''}
                        />
                        {errors.image && <span className="error-message">{errors.image}</span>}
                        {image && <div className="image-preview">
                            <img src={URL.createObjectURL(image)} alt="Preview" />
                        </div>}
                    </div>

                    <button 
                        type="submit" 
                        className="submit-button" 
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </article>
        </>
    );
}

export default CreateJob