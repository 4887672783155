import React, { useState, useEffect } from 'react';
import { Card, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Timeline from './components/timeline';

import MyGroupstimeline from './components/MyGroupstimeline';
import {
	BuzzButton,
	BuzzMentionedUsers,
	BuzzPostModal,
	BuzzSelect,
	BuzzTabs,
	BuzzTextArea,
} from './ibrar-components';
import { Paperclip } from 'lucide-react';
import './css/ibrar-styles/activity.css';
import { ActivityPostsRepositr } from './data-domain/Repositry/AcitvityPostRepo';
import { ActivityPostsUsecases } from './data-domain/Usecases/ActivityPostsUsecases';
import { toast } from 'react-toastify';

const Home = () => {
	//? STATES
	const [activeTab, setActiveTab] = useState(0);
	const [showPop, SetShowPop1] = useState(false);
	const [dataPop1, SetDataPop1] = useState(null);
	const [posts, setPosts] = useState([]);
	const [postCreate, SetpostCreate] = useState(false);
	const [newMembersAnchorEl, setNewMembersAnchorEl] = useState(null);
	// const [newMembersAnchorEl1, setNewMembersAnchorEl1] = useState(null);
	const [description, setDescription] = useState(''); // State for post description
	const [selectedFiles, setSelectedFiles] = useState([]); // State for selected files
	const [isSubmitting, setIsSubmitting] = useState(false); // For loading state during post submission
	const [comment_com, SetComment_com] = useState('');
	const [newMembersAnchorEl1, setNewMembersAnchorEl1] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState('Status');
	const [info, setInfo] = useState([]);
	const [mymentionposts, setMymentionposts] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [dropdownItems, setDropdownItems] = useState([]);
	const [filteredItems, setFilteredItems] = useState(dropdownItems);
	const [mention_email, setMention_email] = useState('');

	//? HOOKS
	const navigate = useNavigate();

	//? INSTANCES
	const activityRepo = new ActivityPostsRepositr();
	const activityUsecase = new ActivityPostsUsecases(activityRepo);

	//? HANDLERS
	const SetShowPop = () => {
		SetShowPop1(!showPop);
	};
	const handleNavigateTo = (data) => {
		if (!data.startsWith('/')) {
			data = `/${data}`;
		}
		navigate(data);
	};

	const handleTabChange = (newValue) => {
		setActiveTab(newValue);
	};

	const handleNewMembersClick = (event) => {
		console.log(event);

		setNewMembersAnchorEl(event.currentTarget);
	};

	const handleFileChange = (event) => {
		setSelectedFiles(event.target.files); // Store selected files in state
	};

	const handleNewMembersClose1 = () => {
		setNewMembersAnchorEl1(null);
	};

	const handleStatusSelect = (status) => {
		console.log(status.target.value);
		// return;

		setSelectedStatus(status.target.value); // Update the selected status
		handleNewMembersClose1(); // Close the menu
	};

	const handleSubmitPost = async () => {
		if (!description && selectedFiles.length === 0) {
			alert('Please add a description or select media');
			return;
		}

		setIsSubmitting(true);
		var selectedStatuss =
			selectedStatus == 'Status' ? 'Public' : selectedStatus;
		const formData = new FormData();
		formData.append('description', description);
		formData.append('type', selectedStatuss);

		// Append each selected file to the formData
		Array.from(selectedFiles).forEach((file) => {
			formData.append('image[]', file);
		});

		try {
			const response = await activityUsecase.handleCreatePost(formData);
			toast.success(response.message);
			SetpostCreate(false);
			setDescription('');
			setSelectedFiles([]);
			handleSubmitGet();
		} catch (error) {
			console.error('Error creating post:', error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleSubmitGet = async () => {
		try {
			const response = await activityUsecase.getAllPosts();
			setPosts(response.data);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const handleMyMentions = async () => {
		try {
			const response = await activityUsecase.getMentionPosts();

			setMymentionposts(response.data);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const fetchUsers = async () => {
		try {
			const response = await activityUsecase.getAllUsers();
			setDropdownItems(response.data); // Assuming the users are in response.data.users
		} catch (error) {
			console.error('Error fetching users:', error);
		}
	};

	useEffect(() => {
		handleSubmitGet();
		fetchUsers();
		handleMyMentions();
		var localinfo1 = localStorage.getItem('userinfo');
		var localinfo = JSON.parse(localinfo1);
		console.log(postCreate);
		setInfo(localinfo);
	}, []);

	const refreshPost = () => {
		handleSubmitGet();
	};

	const SetDataPop = (data) => {
		SetDataPop1(data);
	};

	const handleSubmitCommit = async (data) => {
		const formData = new FormData();
		formData.append('post_id', data);
		formData.append('comment', comment_com);
		formData.append('mention_email', mention_email);

		try {
			const response = await activityUsecase.createPostComment(formData);
			toast.success(response.message);
			SetComment_com('');
			refreshPost(true);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const handleSubmitLike = async (data) => {
		const formData = new FormData();
		formData.append('post_id', data);
		formData.append('impression_type', 1);

		try {
			const response = await activityUsecase.handleLikesDislikes(formData);

			toast.success(response.message);
		} catch (error) {
			console.error('Error creating post:', error);
		}
	};

	const selectItem = (item) => {
		setDescription((prev) => prev.replace(/@\w*$/, `@${item.username} `));
		setMention_email(item.email);
		setShowDropdown(false);
	};

	const handleInputChange = (e) => {
		const value = e.target.value;
		setDescription(value);

		const atIndex = value.lastIndexOf('@');
		const hasSpace = value.includes(' ');

		if (atIndex !== -1 && !hasSpace) {
			// Get the text after the "@" character
			const searchText = value.slice(atIndex + 1);

			// Filter items based on the text after "@"
			const filtered = dropdownItems.filter((item) =>
				item.username.toLowerCase().startsWith(searchText.toLowerCase()),
			);

			setFilteredItems(filtered);

			// Show dropdown only if we have search text and filtered items
			setShowDropdown(filtered.length > 0);
		} else {
			setShowDropdown(false); // Hide dropdown if no "@" or space encountered
		}
	};

	// REMVO POST
	const RemovePostFromList = (id) => {
		console.log(id);
		const filterPosts = posts.filter((post) => post.id !== id);
		setPosts(filterPosts);
	};

	return (
		<>
			<Box className='w-1/2 py-10 m-auto'>
				{/* CREATE POST ELEMENT */}
				<div className='p-5 mb-10 bg-white rounded-md card-container '>
					<div className='flex items-start w-full gap-5'>
						<img
							onClick={() => handleNavigateTo('profile')}
							className='object-cover w-10 h-10 rounded-full cursor-pointer'
							src={
								info
									? info?.data?.user_information?.profile_image
									: 'https://static.vecteezy.com/system/resources/previews/036/594/092/non_2x/man-empty-avatar-photo-placeholder-for-social-networks-resumes-forums-and-dating-sites-male-and-female-no-photo-images-for-unfilled-user-profile-free-vector.jpg'
							}
							alt=''
						/>
						{!postCreate ? (
							<div
								onClick={() => SetpostCreate(true)}
								className='w-full h-10 p-2 px-5 text-sm text-gray-400 border border-gray-400 rounded-full cursor-pointer'
							>
								What's new?
							</div>
						) : (
							<BuzzTextArea
								rowsCount={4}
								name={'post'}
								value={description}
								onChange={handleInputChange}
							/>
						)}
					</div>
					{postCreate && (
						<>
							{showDropdown && (
								<>
									<BuzzMentionedUsers
										buzzMentionedUsers={filteredItems}
										buzzSelectUser={selectItem}
										buzzStyles={styles.dropdownItem}
									/>
								</>
							)}
							<div className='flex items-center justify-between w-full mt-5 attatchment-filters'>
								<div className='relative flex items-center gap-1 overflow-hidden text-sm text-gray-400 attatchment'>
									<span className='flex items-center justify-center w-8 h-8 text-gray-800 bg-gray-200 rounded-full'>
										<Paperclip size={17} />
									</span>
									<span className='content-center h-8 px-3 text-gray-800 bg-gray-200 rounded-full'>
										Attatch media
									</span>
									<span className='text-gray-400'>Max. File size: 512 M</span>

									{/* INPUT FILE */}
									<input
										type='file'
										accept='image/*,video/*'
										onChange={handleFileChange}
										className='absolute left-0 w-full h-full opacity-0 cursor-pointer'
									/>
								</div>

								<div className='filter'>
									<BuzzSelect
										buzzOptionz={[
											{ value: 'Public', label: 'Public' },
											{ value: 'Private', label: 'Private' },
											{ value: 'Friends', label: 'Friends' },
										]}
										buzzSelectedOption={'Choose filter'}
										buzzValue={selectedStatus}
										buzzOnChange={handleStatusSelect}
									/>
								</div>
							</div>

							<div className='flex justify-end w-full gap-2 mt-3 text-sm actions'>
								<BuzzButton
									buzzBtnClick={() => {
										console.log(postCreate);
										SetpostCreate(false);
									}}
									buzzBtnText='Cancel'
									buzzIsPrimary={false}
									buzzIsBtnSmall={false}
								/>
								<BuzzButton
									buzzBtnClick={handleSubmitPost}
									buzzBtnText={isSubmitting ? 'Posting...' : 'Post Update'}
									buzzIsPrimary={true}
									buzzIsBtnSmall={false}
								/>
							</div>
						</>
					)}
				</div>

				{/* TABS SECTION */}
				<BuzzTabs
					buzzTabs={[
						{ label: 'All Memebers' },
						{ label: 'My Groups' },
						{ label: 'Mentions' },
					]}
					buzzOnTabChange={handleTabChange}
					buzzTabActiveIndex={activeTab}
				/>

				{/* POSTS FILTER  */}

				<div className='flex justify-end w-full my-10'>
					<BuzzSelect
						buzzSelectedOption={'Please choose filter'}
						buzzValue={'Everything'}
						buzzOptionz={[
							{ label: 'Everything', value: 'Everything' },
							{ label: 'This Week', value: 'This Week' },
						]}
						buzzOnChange={handleNewMembersClick}
						buzClasses={'!w-1/4  '}
					/>
				</div>

				{/* POSTS TIMELINE */}
				<div className='tags_landing'>
					{activeTab === 0 ? (
						<Timeline
							posts={posts}
							refreshPost={refreshPost}
							SetShowPop={SetShowPop}
							SetDataPop={SetDataPop}
							removePost={RemovePostFromList}
							mentionesUsers={dropdownItems}
						/>
					) : activeTab === 1 ? (
						<MyGroupstimeline />
					) : (
						<>
							{mymentionposts.length > 0 ? (
								<Timeline
									posts={mymentionposts}
									refreshPost={refreshPost}
									SetShowPop={SetShowPop}
									SetDataPop={SetDataPop}
									mentionesUsers={dropdownItems}
								/>
							) : (
								<Card
									sx={{
										boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
										borderRadius: '12px',
										padding: '20px',
										textAlign: 'center',
									}}
								>
									<Typography variant='body1' color='textSecondary'>
										Sorry, there was no activity found. Please try a different
										filter.
									</Typography>
								</Card>
							)}
						</>
					)}
				</div>
			</Box>
			{showPop && (
				<>
					<BuzzPostModal
						buzzComments={dataPop1.comments}
						buzzImg={dataPop1.images[0].image}
						buzzBtnClick={() => {
							handleSubmitCommit(dataPop1.id);
						}}
						buzzOnCommentChange={(e) => {
							SetComment_com(e.target.value);
						}}
						buzzCommentValue={comment_com}
						buzzOnCloseModal={() => {
							SetShowPop1(false);
						}}
						buzzPostTitle={dataPop1.title}
						buzzPostDate={dataPop1.created_at}
					/>
				</>
			)}
		</>
	);
};
const styles = {
	dropdown: {
		position: 'absolute',
		top: '60%',
		left: '0px',
		width: '300px',
		border: '1px solid rgb(204, 204, 204)',
		backgroundColor: 'white',
		zIndex: '1',
		marginTop: '-45px',
		marginLeft: '15%',
		height: '160px',
		overflow: 'scroll',
	},
	dropdownList: {
		listStyle: 'none',
		margin: 0,
		padding: 0,
	},
	dropdownItem: {
		padding: '10px',
		cursor: 'pointer',
	},
	dropdownItemHover: {
		backgroundColor: '#f0f0f0',
	},
};
export default Home;
