import React, { useCallback, useEffect, useState } from 'react';
// import "https://buzzinguniverse.iqspark.org/css_file/forms.css";
import { Helmet } from 'react-helmet';
const QuestionsList = () => {
    const [articles, setArticles] = useState([]);
    const [pageNumber, setPageNumber] = useState(1)
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState('');

    const debouncedSearch = useCallback(
        (() => {
            let timeoutId;
            return (searchValue) => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    setArticles([]);
                    setPageNumber(1);
                    fetchArticles(1, searchValue);
                }, 3000);
            };
        })(),
        []
    );

    const handleLocationChange = (e) => {
        const value = e.target.value;
        setSearch(value);
        debouncedSearch(value);
    };
    const fetchArticles = async (page, search = '') => {
        setPageNumber(page)
        setLoading(true)
        try {
            const response = await fetch(`https://api.buzzinguniverse.iqspark.org/public/api/search?${search ? `query=${search}` : 'query=first'}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setArticles(data?.data)
        } catch (error) {
            console.error('Error fetching groups:', error);
        }
        setLoading(false)
    };

    useEffect(() => {
        fetchArticles(1)
    }, []);
    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://buzzinguniverse.iqspark.org/css_file/forms.css"
                />
            </Helmet>
            <div class="js-hiraku-offcanvas" aria-hidden="true" aria-labelledby="hiraku-offcanvas-btn-0" id="js-hiraku-offcanvas-0" aria-label="close">
                <div id="beehive-social-panel" class="beehive-social-panel js-hiraku-offcanvas-sidebar js-hiraku-offcanvas-sidebar-left" role="navigation">
                    <div class="inner-panel ass-scrollbar mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar">
                        <div id="mCSB_1" class="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" tabindex="0">
                            <div id="mCSB_1_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" dir="ltr">
                                <div class="panel-block dark">
                                    <a href="/" class="panel-logo item">
                                        <img src="https://buzzinguniverse.com/wp-content/uploads/2023/06/809890.png" alt="BuzzingUniverse- Login or Signup" class="mCS_img_loaded" />
                                    </a>
                                    <div class="my-card item">
                                        <div class="info">
                                            <a href="/members/shazzy/" class="profile-avatar">
                                                <img src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-96.png" alt="Shazzy" class="avatar mCS_img_loaded" />
                                            </a>
                                            <div class="profile-name">
                                                <a href="/members/shazzy/" class="name ellipsis">Shazzy</a>
                                                <small>Member</small>
                                            </div>
                                        </div>
                                        <ul class="connections">
                                            <li>
                                                <span class="count">0</span>
                                                <p class="mute">Friends</p>
                                            </li>
                                            <li>
                                                <span class="count">0</span>
                                                <p class="mute">Groups</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="panel-block light">
                                    <div class="panel-menu item">
                                        <ul id="menu-dashboard-menu" class="navbar-panel">
                                            <li id="menu-item-74" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-74">
                                                <a href="/activity/">
                                                    <i class=" uil-notebooks"></i>
                                                    <span class="nav-link-text">Activity</span>
                                                </a>
                                            </li>
                                            <li id="menu-item-80" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-80">
                                                <a href="/photos/">
                                                    <i class="uil-image-v"></i>
                                                    <span class="nav-link-text">Photos</span>
                                                </a>
                                            </li>
                                            <li id="menu-item-83" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-83">
                                                <a href="/members/">
                                                    <i class="uil-user"></i>
                                                    <span class="nav-link-text">People</span>
                                                </a>
                                            </li>
                                            <li id="menu-item-78" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-78">
                                                <a href="/groups/">
                                                    <i class="uil-users-alt"></i>
                                                    <span class="nav-link-text">Groups</span>
                                                </a>
                                            </li>
                                            <li id="menu-item-75" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-75">
                                                <a href="/adverts/">
                                                    <i class="uil-tv-retro"></i>
                                                    <span class="nav-link-text">Adverts</span>
                                                </a>
                                            </li>
                                            <li id="menu-item-2014" class="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-2014">
                                                <a href="/forums/" aria-current="page">
                                                    <i class="uil-comments"></i>
                                                    <span class="nav-link-text">Forums</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="mCSB_1_scrollbar_vertical" class="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical">
                            <div class="mCSB_draggerContainer">
                                <div id="mCSB_1_dragger_vertical" class="mCSB_dragger">
                                    <div class="mCSB_dragger_bar"></div>
                                    <div class="mCSB_draggerRail"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                width: "80%",
                margin: "0 auto",
                marginTop: "70px"
            }} id="beehive-page" class="site">
                <header id="sochead" class="site-header social-header user-nav-active">
                    <nav class="navbar beehive-navbar social fixed-top">
                        <div class="container">
                            <button class="beehive-toggler account-toggler js-hiraku-offcanvas-btn" type="button" data-toggle-offcanvas="#js-hiraku-offcanvas-1" aria-expanded="false" aria-label="Menu" aria-controls="js-hiraku-offcanvas-1" id="hiraku-offcanvas-btn-1">
                                <img alt="" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-24.png" srcset="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-48.png  2x" class="avatar avatar-24 photo" height="24" width="24" decoding="async" />
                            </button>
                            <button class="beehive-toggler panel-toggler js-hiraku-offcanvas-btn" type="button" data-toggle-offcanvas="#js-hiraku-offcanvas-0" aria-expanded="false" aria-label="Menu" aria-controls="js-hiraku-offcanvas-0" id="hiraku-offcanvas-btn-0">
                                <span class="icon-bar bar1"></span>
                                <span class="icon-bar bar2"></span>
                                <span class="icon-bar bar3"></span>
                            </button>

                            <ul id="navbar-user" class="navbar-nav navbar-user">
                                <li id="friend-requests-list" class="nav-item dropdown friend-requests-list">
                                    <a class="nav-link dropdown-toggle" href="#" id="nav_friend_requests" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="uil-user-plus"></i>
                                        <span class="nav-item-title">Friend Requests</span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="nav_friend_requests">
                                        <div class="dropdown-title">Friend requests</div>
                                        <div class="alert-message">
                                            <div class="alert alert-warning" role="alert">No friend request.</div>
                                        </div>
                                        <div class="dropdown-footer">
                                            <a href="/members/shazzy/friends/requests/" class="button">All Requests</a>
                                        </div>
                                    </div>
                                </li>
                                <li id="notification-list" class="nav-item dropdown notification-list">
                                    <a class="nav-link dropdown-toggle" href="#" id="nav_notification" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="uil-bell"></i>
                                        <span class="nav-item-title">Notifications</span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="nav_notification">
                                        <div class="dropdown-title">Notifications</div>
                                        <div class="alert-message">
                                            <div class="alert alert-warning" role="alert">No notifications found</div>
                                        </div>
                                        <div class="dropdown-footer">
                                            <a href="/members/shazzy/notifications/unread/" class="button">All Notifications</a>
                                        </div>
                                    </div>
                                </li>
                                <li id="private-message-list" class="nav-item dropdown private-message-list">
                                    <a class="nav-link dropdown-toggle" href="#" id="nav_private_messages" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="uil-envelope-open"></i>
                                        <span class="nav-item-title">Messages</span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="nav_private_messages">
                                        <div class="dropdown-title">Unread messages</div>
                                        <div class="alert-message">
                                            <div class="alert alert-warning" role="alert">No messages to read.</div>
                                        </div>
                                        <div class="dropdown-footer">
                                            <a href="/members/shazzy/messages/inbox/" class="button">All Messages</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="mini-cart nav-item">
                                    <a href="/cart/" class="cart-contents nav-link" title="View Cart">
                                        <i class="uil-cart"></i>
                                    </a>
                                </li>
                                <li id="myaccount-url-list" class="nav-item dropdown myaccount-url-list">
                                    <a class="nav-link dropdown-toggle" href="#" id="nav_my_account" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <img loading="lazy" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-200.png" class="avatar user-1398-avatar avatar-200 photo" width="200" height="200" alt="Profile picture of Shazzy" />
                                        <span class="account-name">@ shazzy</span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="nav_my_account">
                                        <ul id="menu-account-menu" class="member-account-menu">
                                            <li id="menu-item-4289" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4289">
                                                <a href="/activity/">Home</a>
                                            </li>
                                            <li id="menu-item-84" class="bp-menu bp-activity-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-84">
                                                <a href="/members/shazzy/activity/">Activity</a>
                                            </li>
                                            <li id="menu-item-85" class="bp-menu bp-profile-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-85">
                                                <a href="/members/shazzy/profile/">Profile</a>
                                            </li>
                                            <li id="menu-item-86" class="bp-menu bp-friends-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-86">
                                                <a href="/members/shazzy/friends/">Friends</a>
                                            </li>
                                            <li id="menu-item-87" class="bp-menu bp-groups-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-87">
                                                <a href="/members/shazzy/groups/">Groups</a>
                                            </li>
                                            <li id="menu-item-88" class="bp-menu bp-my-adverts-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-88">
                                                <a href="/members/shazzy/my-adverts/">Adverts</a>
                                            </li>
                                            <li id="menu-item-5660" class="bp-forums-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-5660">
                                                <a href="/members/Shazzy/forums/">Forums</a>
                                            </li>
                                            <li id="menu-item-91" class="bp-menu bp-notifications-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-91">
                                                <a href="/members/shazzy/notifications/">Notifications</a>
                                            </li>
                                            <li id="menu-item-92" class="bp-menu bp-messages-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-92">
                                                <a href="/members/shazzy/messages/">Messages</a>
                                            </li>
                                            <li id="menu-item-93" class="bp-menu bp-settings-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-93">
                                                <a href="/members/shazzy/settings/">Settings</a>
                                            </li>
                                            <li id="menu-item-94" class="bp-menu bp-logout-nav menu-item menu-item-type-custom menu-item-object-custom menu-item-94">
                                                <a href="/wp-login.php?action=logout&amp;redirect_to=https%3A%2F%2Fbuzzinguniverse.com%2Fforums%2F&amp;_wpnonce=9e27f48fe8">Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>

                            <div id="beehive-ajax-search" class="beehive-ajax-search">
                                <form role="search" method="get" id="ajax-search-form" class="ajax-search-form form-inline" action="https://buzzinguniverse.com/">
                                    <div class="search-field">
                                        <i class="icon ion-android-search"></i>
                                        <input
                                            type="text"
                                            name="search_location"
                                            id="search_location"
                                            placeholder="Location"
                                            value={search}
                                            onChange={handleLocationChange}
                                        />
                                        <span class="beehive-loading-ring"></span>
                                    </div>
                                    <div class="search-button">
                                        <button type="submit" class="search-submit">
                                            <i class="icon ion-android-search"></i>
                                        </button>
                                    </div>
                                </form>
                                <div id="ajax-search-result"></div>
                            </div>
                            <div main-logo-header="">
                                <a class="navbar-brand" href="/">
                                    <img src="https://buzzinguniverse.com/wp-content/uploads/2023/06/809890-1.png" title="BuzzingUniverse- Login or Signup" alt="BuzzingUniverse- Login or Signup" class="default-logo" />
                                </a>
                            </div>
                        </div>
                    </nav>
                </header>

                <div id="content" class="site-content">
                    <div id="primary" class="content-area">
                        <div class="layout social">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-8 col-main">
                                        <main id="main" class="main-content">
                                            <div class="beehive-title-bar social">
                                                <div class="title-bar-wrapper">
                                                    <div class="title-wrapper screen-reader-text">
                                                        <h1 class="title h3">Forums</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <article id="post-62" class="post-62 forum type-forum status-publish hentry beehive-post">
                                                <div class="entry-content clearfix">
                                                    <div id="bbpress-forums" class="bbpress-wrapper">
                                                        <div class="bbp-search-form">
                                                            <form role="search" method="get" id="bbp-search-form">
                                                                <div>
                                                                    <label class="screen-reader-text hidden" for="bbp_search">Search for:</label>
                                                                    <input type="hidden" name="action" value="bbp-search-request" />
                                                                    <input
                                                                        type="text"
                                                                        name="search_location"
                                                                        id="search_location"
                                                                        placeholder="search"
                                                                        value={search}
                                                                        onChange={handleLocationChange}
                                                                    />
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <ul id="forums-list-0" class="bbp-forums">
                                                            <li class="bbp-header">
                                                                <ul class="forum-titles">
                                                                    {/* <li class="bbp-forum-info">Forum</li> */}
                                                                    <li class="bbp-forum-info">Article</li>
                                                                    <li class="bbp-forum-topic-count">Tags</li>
                                                                    {/* <li class="bbp-forum-reply-count">Posts</li> */}
                                                                    <li class="bbp-forum-freshness">Created At</li>
                                                                </ul>
                                                            </li>

                                                            <li class="bbp-body">
                                                                {
                                                                    articles?.articles?.map((item, index) => {
                                                                        return (
                                                                            <ul key={item?.id} id="bbp-forum-6402" class="loop-item-0 bbp-forum-status-open bbp-forum-visibility-publish odd  post-6402 forum type-forum status-publish hentry beehive-post animate-item slideInUp" style={{ visibility: "visible" }}>
                                                                                <li class="bbp-forum-info">
                                                                                    <span class="bbp-title-icon forum-icon color-primary h4">
                                                                                        <img src={item?.feature_image} />
                                                                                    </span>
                                                                                    <h4 class="forum-title">
                                                                                        <a class="bbp-forum-title" href="/forums/forum/buzzing-universe-2/">{item?.title}</a>
                                                                                    </h4>
                                                                                    <div class="bbp-forum-content">{
                                                                                        item?.tags?.map((item) => { return <div style={{ marginLeft: '5px' }}>{item}</div> })
                                                                                    }</div>
                                                                                </li>
                                                                                <li class="bbp-forum-topic-count bbp-topic-color">{item?.tags?.length}</li>
                                                                                {/* <li class="bbp-forum-reply-count bbp-post-color">2</li> */}
                                                                                <li class="bbp-forum-freshness">
                                                                                    <span class="mute">
                                                                                        <a href="/forums/topic/minimize-ventilation-system-noise-with-expert-fan-balancing-services/" title="Minimize Ventilation System Noise with Expert Fan Balancing Services">{item?.createdAt}</a>
                                                                                    </span>
                                                                                    <p class="bbp-topic-meta">
                                                                                        <span class="bbp-topic-freshness-author">
                                                                                            {/* <span class="bbp-author-avatar">
                                                                                    <img alt="" src="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-14.png" srcset="https://buzzinguniverse.com/wp-content/uploads/avatar-privacy/cache/custom/1/131ecfcd673b203978922cbf323f9085bbba409c1277a70d6a489fffb7a202f4-28.png  2x" class="avatar avatar-14 photo" height="14" width="14" loading="lazy" decoding="async" />
                                                                                </span>
                                                                                <span class="bbp-author-name">Anonymous</span> */}
                                                                                            {item?.created_at}
                                                                                        </span>
                                                                                    </p>
                                                                                </li>
                                                                            </ul>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    articles?.jobs?.map((item, index) => {
                                                                        return (
                                                                            <li key={index} className="animate-item slideInUp post-281 job_listing type-job_listing status-publish has-post-thumbnail hentry job_listing_type-full-time beehive-post job-type-full-time" data-longitude="" data-latitude="" style={{ visibility: "visible", marginBottom: '10px' }}>
                                                                                <div className="job-list-item">
                                                                                    <div>
                                                                                        <figure>
                                                                                            <img className="company_logo" src={item?.user?.user_company?.logo} alt="Clinivex Analytics" />
                                                                                        </figure>
                                                                                    </div>
                                                                                    <div className="job-info">
                                                                                        <h4 className="job-title">
                                                                                            <a href="/job/marketing-data-enrichment-specialist/">{item?.title}</a>
                                                                                        </h4>
                                                                                        <div className="about-company">
                                                                                            <span className="address mute ellipsis">{item?.location}</span>
                                                                                            <p className="company-name color-primary ellipsis">{item?.user?.user_company?.name}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="job-listing-meta">
                                                                                        <ul className="job-types-lists ellipsis">
                                                                                            <li className="job-type full-time">{item?.job_type}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    articles?.adverts?.map((item, index) => {
                                                                        return (
                                                                            <li key={index} className="animate-item slideInUp post-281 job_listing type-job_listing status-publish has-post-thumbnail hentry job_listing_type-full-time beehive-post job-type-full-time" data-longitude="" data-latitude="" style={{ visibility: "visible", marginBottom: '10px' }}>
                                                                                <div className="job-list-item">
                                                                                    <div>
                                                                                        <figure>
                                                                                            <img className="company_logo" src={item?.media?.[0]} alt="Clinivex Analytics" />
                                                                                        </figure>
                                                                                    </div>
                                                                                    <div className="job-info">
                                                                                        <h4 className="job-title">
                                                                                            <a href="/job/marketing-data-enrichment-specialist/">{item?.title}</a>
                                                                                        </h4>
                                                                                        <div className="about-company">
                                                                                            <span className="address mute ellipsis">{item?.location}</span>
                                                                                            <p className="company-name color-primary ellipsis">{item?.contact_email}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="job-listing-meta">
                                                                                        <ul className="job-types-lists ellipsis">
                                                                                            <li className="job-type full-time">{item?.contact_name}</li>
                                                                                            <li className="job-type full-time" style={{ marginTop: '20px', cursor: 'pointer' }}>View Details</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>

                                            </article>

                                        </main>

                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <div class="js-hiraku-offcanvas" aria-hidden="true" aria-labelledby="hiraku-offcanvas-btn-1" id="js-hiraku-offcanvas-1" aria-label="close">
                <div id="navbar-account-sidebar" class="js-hiraku-offcanvas-sidebar js-hiraku-offcanvas-sidebar-right" role="navigation"></div>
            </div>


            <div id="buddy-chat-app" class="dash">
                <div id="buddy-chat-buddies" class="collapsed">
                    <div class="buddy-chat-buddies__container">
                        <div class="header-container">
                            <div class="header-title">
                                <span class="dashicons dashicons-format-chat"></span>
                                <div class="window-title">
                                    <h5>Messenger</h5>
                                </div>
                            </div>
                            <div class="dropd-group dropd-dr">
                                <a class="dropd-control mute">
                                    <span class="dashicons dashicons-admin-generic"></span>
                                </a>
                                <div class="dropd-menu">
                                    <a class="dropd-item">Mute</a>
                                </div>
                            </div>
                        </div>
                        <div class="vb vb-invisible">
                            <div class="buddy-chat-buddies__content vb-content">
                                <ul class="buddy-chat-nav-tabs">
                                    <li class="item">
                                        <a class="item-link">Friends</a>
                                    </li>
                                    <li class="item">
                                        <a class="item-link">Groups</a>
                                    </li>
                                </ul>
                                <div id="buddy-list" class="bpc-tab-content">


                                </div>
                            </div>
                            <div class="vb-dragger">
                                <div class="vb-dragger-styler"></div>
                            </div>
                        </div>
                        <div id="buddy-chat-buddies__collapser" class="buddy-chat-buddies__collapser">
                            <div class="collapse-icon">
                                <span class="dashicons dashicons-arrow-right-alt2"></span>
                            </div>
                            <div class="action-text">
                                <h5>Collapse</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="buddy-chat-windows">
                    <ul class="bpc-chat-windows-list">


                    </ul>
                </div>
            </div>
            <div id="buddypress-edit-activity-wrapper">
                <form id="frm_buddypress-edit-activity" method="POST">
                    <input type="hidden" name="action_get" value="buddypress-edit-activity-get" />
                    <input type="hidden" name="action_save" value="buddypress-edit-activity-save" />
                    <input type="hidden" name="buddypress_edit_activity_nonce" value="8a986f21f7" />
                    <input type="hidden" name="activity_id" value="" />
                    <div class="field ac-textarea">
                        <textarea class="bp-suggestions" id="whats-new" cols="50" rows="10" name="activity_content"></textarea>
                    </div>
                </form>
            </div>
        </>
    );
};

export default QuestionsList;
