import { Forward, MessageCircle, ThumbsUp } from 'lucide-react';
import React from 'react';

const BuzzPostActions = ({
	buzzOnHandleLike,
	buzzLikeCounts,
	buzzCommentsCount,
	buzzHandleShare,
	buzzToggleComment,
}) => {
	return (
		<div className='flex items-center gap-5 py-5 mt-5 text-sm text-gray-500 border-t border-b'>
			<div
				className='flex items-center gap-1 cursor-pointer'
				onClick={buzzOnHandleLike}
			>
				<ThumbsUp size={18} /> <span>Like {buzzLikeCounts} </span>
			</div>
			<div
				onClick={buzzToggleComment}
				className='flex items-center gap-1 cursor-pointer'
			>
				<MessageCircle size={18} />{' '}
				<span>Comments {buzzCommentsCount || 0}</span>
			</div>
			<div
				onClick={buzzHandleShare}
				className='flex items-center gap-1 cursor-pointer'
			>
				<Forward size={18} /> <span>Share</span>
			</div>
		</div>
	);
};

export default BuzzPostActions;
