export class ActivityPostsUsecases {
	constructor(activityRepo) {
		this.activityRepo = activityRepo;
	}

	// HANDLE FAVOURITE POST
	async handleFavouritePost(payload) {
		const data = await this.activityRepo.handleFavouritePost(payload);
		return data;
	}

	// LIKES
	async handleLikesDislikes(payload) {
		const data = await this.activityRepo.handleLikesDislikes(payload);
		return data;
	}

	// SAHRE POST
	async handleSharePost(id) {
		const data = await this.activityRepo.handleSharePost(id);
		return data;
	}

	// DELETE POST
	async handleDeletePost(id) {
		const data = await this.activityRepo.DeletePost(id);
		return data;
	}

	// CREATE POST
	async handleCreatePost(id) {
		const data = await this.activityRepo.CreatePost(id);
		return data;
	}

	// GET POSTS
	async getAllPosts() {
		const data = await this.activityRepo.GetPosts();
		return data;
	}

	// GET USERS
	async getAllUsers() {
		const data = await this.activityRepo.GetUsers();
		return data;
	}

	// MENTION POSTS
	async getMentionPosts() {
		const data = await this.activityRepo.GetMentionPosts();
		return data;
	}

	// CREATE COMMETN
	async createPostComment(payload) {
		const data = await this.activityRepo.CreatePostComment(payload);
		return data;
	}
}
