export class CommentsUsecases {
	constructor(commentsRepo) {
		this.commentsRepo = commentsRepo;
	}

	// UPDATE COMMETS
	async updateComment(id, payload) {
		const data = await this.commentsRepo.updateComment(id, payload);
		console.log(data);
		return data;
	}

	// DELETE COMMENT
	async deleteComment(id) {
		const data = await this.commentsRepo.DeleteComment(id);
		return data;
	}

	// CREATE COMMENT
	async createComment(payload) {
		const data = await this.commentsRepo.CreateComment(payload);
		return data;
	}
}
