import React from 'react';

const BuzzSelect = ({
	buzzOptionz,
	buzzSelectedOption,
	buzzValue,
	buzzOnChange,
	buzClasses,
}) => {
	return (
		<select
			className={
				buzClasses +
				'w-full p-2 text-sm border rounded-full outline-none cursor-pointer'
			}
			name=''
			id=''
			value={buzzValue}
			onChange={buzzOnChange}
		>
			<option className='text-xs' value='' disabled selected>
				{buzzSelectedOption}
			</option>
			{buzzOptionz.map((option) => (
				<option className='text-xs' key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</select>
	);
};

export default BuzzSelect;
