import React, { useState, useRef } from 'react';
// import '../src/css/submit.css';

const Text = () => {
	const [text, setText] = useState('');
	const fileInputRef = useRef(null); // Reference to the file input

	const handleChange = (event) => {
		setText(event.target.value);
	};

	const downloadTextFile = () => {
		const blob = new Blob([text], { type: 'text/plain' });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = 'text.txt';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		URL.revokeObjectURL(url);
	};

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		if (file && file.type === 'text/plain') {
			const reader = new FileReader();
			reader.onload = (e) => setText(e.target.result);
			reader.readAsText(file);
		}
	};

	const clearText = () => {
		setText('');
	};

	const totalWords = text.trim() ? text.trim().split(/\s+/).length : 0;
	const totalCharacters = text.length;

	return (
		<>
			<div
				className='bp-nouveau page-template page-template-page-templates page-template-blank page-template-page-templatesblank-php page page-id-539 wp-custom-logo theme-beehive woocommerce-js beehive beehive-guest-user beehive-child elementor-default elementor-kit-32 elementor-page elementor-page-539 blank-template js js-hiraku-offcanvas-body e--ua-blink e--ua-chrome e--ua-webkit'
				data-elementor-device-mode='desktop'
			>
				<div
					id='beehive-page'
					style={{ backgroundColor: '#000' }}
					className='site'
				>
					<header
						id='mastheadtop'
						className='site-header default-header user-nav-active'
					>
						<nav
							className='navbar beehive-navbar default fixed-top to-top'
							style={{
								backgroundColor: '#092972',
								display: 'flex',
								justifyContent: 'end',
							}}
						>
							<div
								className='container'
								style={{
									margin: '0',
									flexDirection: 'row',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'end',
								}}
							>
								<a className='navbar-brand' href='/'>
									<img
										src='https://buzzinguniverse.com/wp-content/uploads/2023/06/Logo.webp'
										title='BuzzingUniverse- Login or Signup'
										alt='BuzzingUniverse- Login or Signup'
										className='default-logo'
										style={{ height: '30px' }}
									/>
								</a>
							</div>
						</nav>
					</header>
				</div>
			</div>
			<div>
				<textarea
					value={text}
					onChange={handleChange}
					rows='10'
					cols='50'
					placeholder='Write your text here...'
					className='text-editor'
				/>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<button onClick={downloadTextFile} className='edit-button'>
						Download
					</button>
					<button
						onClick={() => fileInputRef.current.click()}
						className='edit-button'
					>
						Upload
					</button>
					<input
						type='file'
						accept='.txt'
						onChange={handleFileUpload}
						ref={fileInputRef}
						style={{ display: 'none' }} // Hide the file input
					/>
					<button onClick={clearText} className='edit-button'>
						New File
					</button>
					<button className='edit-button'>Total Words: {totalWords}</button>
					<button className='edit-button'>
						Total Characters: {totalCharacters}
					</button>
				</div>
			</div>
		</>
	);
};

export default Text;
